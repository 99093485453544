// @flow strict
import { api as appApi } from '@/core/api';

import { type UserType, type UserDtoType } from '../User';

export const me = (): Promise<UserType> => appApi<UserType, void>('GET', 'users/me');

export const updateProfile = (profile: $Shape<UserDtoType>): Promise<UserType> =>
    appApi<UserType, UserDtoType>('PATCH', 'users/me', { body: profile });

export const uploadAvatar = (avatar: Blob): Promise<UserType> => appApi('PUT', 'users/avatar', { body: { avatar } });

export const deleteAvatar = (): Promise<UserType> => appApi('DELETE', 'users/avatar');
