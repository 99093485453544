// @flow
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Container, Heading, useNotificationActions, NOTIFICATION_TYPE } from '@xyz-school/xyz-react-frontend';

import { api, useCurrentUser } from '@/model/User';
import { useForm } from '@/core/form';

import SubmitControls from '../SubmitControls';

import styles from './FormPassword.scss';

type FormType = {|
    oldPassword: string,
    password1: string,
    password2: string,
|};

const initialValues: FormType = {
    oldPassword: '',
    password1: '',
    password2: '',
};

const ProfileFormSecurity = (): Node => {
    const { t } = useTranslation('profile');
    const { pushNotification } = useNotificationActions();
    const { updateUser } = useCurrentUser();

    const { handleSubmit, handleChange, values, errors, isDisabled } = useForm<FormType>(
        initialValues,
        async (submitValues, onError, resetForm): Promise<void> => {
            try {
                const newUser = await api.changePassword(submitValues);
                updateUser(newUser);
                resetForm();
                pushNotification({ type: NOTIFICATION_TYPE.success, message: t('SAVED') });
            } catch (error) {
                onError(error);
            }
        },
        ['oldPassword', 'password1', 'password2'],
    );

    const inputs = [
        {
            component: (
                <Input
                    name="oldPassword"
                    type="password"
                    value={values.oldPassword}
                    label={t('PAS_PASSWORD_OLD')}
                    onChange={handleChange}
                    errorMessage={errors.oldPassword}
                    autoComplete="current-password"
                />
            ),
            key: 'oldPassword',
        },
        {
            component: (
                <Input
                    name="password1"
                    type="password"
                    value={values.password1}
                    label={t('PAS_PASSWORD')}
                    onChange={handleChange}
                    errorMessage={errors.password1}
                    autoComplete="new-password"
                />
            ),
            key: 'password1',
        },
        {
            component: (
                <Input
                    name="password2"
                    type="password"
                    value={values.password2}
                    label={t('PAS_PASSWORD_CONFIRM')}
                    onChange={handleChange}
                    errorMessage={errors.password2}
                    autoComplete="new-password"
                />
            ),
            key: 'password2',
        },
    ];

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div>
                <Heading level={3}>{t('PAS_TITLE')}</Heading>
                {inputs.map(({ component, key }) => (
                    <Container marginTop={2} marginTopSm={1.5} key={key}>
                        {component}
                    </Container>
                ))}
            </div>

            <Container marginTop={4} marginTopSm={2}>
                <SubmitControls isSubmitDisabled={isDisabled} />
            </Container>
        </form>
    );
};

export default ProfileFormSecurity;
