// @flow strict
import { getCommonHeaders, coreApi, type MethodType } from './api';

export const getFormData = <T: {}>(data: T): FormData => {
    const formData = new FormData();
    Object.keys(data).forEach((name) => {
        if (data[name] instanceof Blob) {
            formData.append(name, data[name], `${name}.jpg`);
        } else if (typeof data[name] !== 'undefined') {
            formData.append(name, typeof data[name] === 'object' ? JSON.stringify(data[name]) : data[name]);
        }
    });

    return formData;
};

export const parseFileData = <T: {}>(data: T): { body: FormData, headers: { [name: string]: string } } => ({
    headers: {
        ...getCommonHeaders(),
    },
    body: getFormData(data),
});

export const fileApi = async <R: {}, T: {}>(method: MethodType, url: string, data: T): Promise<R> => {
    const { headers, body } = parseFileData(data);
    const reqOptions = {
        method,
        headers,
        body,
    };

    return coreApi({ url, query: '' }, reqOptions);
};
