// @flow strict
import React, { type Node } from 'react';
import { Button } from '@xyz-school/xyz-react-frontend';
import { useTranslation } from 'react-i18next';
import styles from './ActionButtons.scss';

export type Props = {
    onCancel: () => void,
    isDisabled?: boolean,
};

const ActionButtons = ({ onCancel, isDisabled }: Props): Node => {
    const { t } = useTranslation(['actionButtons']);
    return (
        <div className={styles.wrapper}>
            <Button onClick={onCancel} size="large" isFullWidth className={styles.button}>
                {t('CANCEL')}
            </Button>
            <Button
                type="submit"
                styleType="primary"
                size="large"
                isFullWidth
                className={styles.button}
                isDisabled={isDisabled}
            >
                {t('SAVE')}
            </Button>
        </div>
    );
};

export default ActionButtons;
