// @flow strict
import { useFormik, type UseFormikReturn, type HandleChangeType } from 'formik';
import { useTranslation, type TFunction } from 'react-i18next';

import useOnSubmit, { type SubmitType } from './useOnSubmit';

type ValidateType<FormType> = (formValues: FormType) => { [name: string]: string };
type RequiredValuesType = Array<string>;

export const getValidate = <FormType: {}>(
    requiredValues: RequiredValuesType,
    additionalValidation?: ValidateType<FormType> = () => ({}),
    t: TFunction,
): ValidateType<FormType> => (formValues) => {
    const validationArray = requiredValues
        .map((name) => {
            const value = !formValues[name] ? t('REQUIRED') : '';
            return [name, value];
        })
        .filter((item) => item[1]);
    return { ...Object.fromEntries(validationArray), ...additionalValidation(formValues) };
};

type GetHandleChangeType = ({
    +setFieldError: $PropertyType<UseFormikReturn, 'setFieldError'>,
    +handleChange: $PropertyType<UseFormikReturn, 'handleChange'>,
}) => HandleChangeType;

export const getHandleChange: GetHandleChangeType = (formik) => (event) => {
    formik.setFieldError(event.currentTarget.name);
    return formik.handleChange(event);
};

const useForm = <FormType: {}>(
    initialValues: FormType,
    submit: SubmitType<FormType>,
    requiredValues: RequiredValuesType,
    additionalValidation?: ValidateType<FormType>,
): {| ...UseFormikReturn, isDisabled: boolean |} => {
    const { t } = useTranslation(['errors']);

    const onSubmit = useOnSubmit(submit);
    const formik = useFormik<FormType>({
        initialValues,
        onSubmit,
        validate: getValidate(requiredValues, additionalValidation, t),
        validateOnChange: false,
    });

    const { isValid, isSubmitting } = formik;

    const isDisabled = !isValid || isSubmitting;

    return { ...formik, isDisabled, handleChange: getHandleChange(formik) };
};

export default useForm;
