// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button, Container, GridCol, GridRow, Heading, Icon, Separator, Text } from '@xyz-school/xyz-react-frontend';

import styles from './ProjectCard.scss';

type Props = {|
    description: string,
    label?: string,
    title: string,
    link?: ?string,
|};

const ProjectCard = ({ description, label, title, link }: Props): Node => {
    const { t } = useTranslation(['home']);

    return (
        <a href={link} target="_blank" rel="noreferrer" className={styles.projectCard}>
            <Separator marginTop={0} marginBottom={3} marginBottomXs={2} />
            <Container marginTop={3} marginTopXs={2} marginBottom={8} marginBottomXs={4}>
                <div className={classNames(styles.row, styles.spaceBetween)}>
                    <div className={styles.row}>
                        <Heading level={2}>{title}</Heading>
                        {label && (
                            <Container marginLeft={2} className={styles.label}>
                                <Text color="text-inverted" size="small">
                                    {label}
                                </Text>
                            </Container>
                        )}
                    </div>
                    {link && <Icon name="arrow-right" color="text" className={styles.arrowIcon} />}
                </div>
                <Container marginTop={2} marginTopXs={1} marginBottom={3} marginBottomXs={2}>
                    <GridRow>
                        <GridCol xl={6} lg={7} sm={3} xs={4}>
                            <Text>{description}</Text>
                        </GridCol>
                    </GridRow>
                </Container>
                {link && <Button styleType="primary">{t('PROJECT_CARD_ACTION_BUTTON_TEXT')}</Button>}
            </Container>
        </a>
    );
};

export default ProjectCard;
