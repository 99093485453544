// @flow strict
import React, { type Node } from 'react';
import { Grid } from '@xyz-school/xyz-react-frontend';

import Section from './Section';

import styles from './CenteredLayout.scss';

type Props = {
    children: Node,
    className?: string,
};

const CenteredLayout = ({ children, className }: Props): Node => (
    <Grid.Row className={className}>
        <Grid.Item>
            <div className={styles.wrapper}>{children}</div>
        </Grid.Item>
    </Grid.Row>
);

CenteredLayout.Section = Section;

export default CenteredLayout;
