// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { PageMetaTags } from '@xyz-school/xyz-react-frontend';
import { useParams, Redirect } from 'react-router-dom';

import MainLayout, { LayoutHeading } from '@/features/MainLayout';
import CenteredLayout from '@/features/CenteredLayout';

import Form from './Form';

const PageNewPassword = (): Node => {
    const { t } = useTranslation(['newPassword']);
    const { key } = useParams();

    if (key) {
        return (
            <MainLayout>
                <PageMetaTags title={t('TITLE')} />
                <LayoutHeading>{t('TITLE')}</LayoutHeading>
                <CenteredLayout>
                    <Form keyToken={key} />
                </CenteredLayout>
            </MainLayout>
        );
    }
    return <Redirect to="/" />;
};

export default PageNewPassword;
