// @flow strict
import { api as appApi } from '@/core/api';

import { type UserType } from '../User';

type ChangePasswordType =
    | {| oldPassword: string, password1: string, password2: string |}
    | {| key: string, password1: string, password2: string, remember: boolean |};

export const changePassword = (data: ChangePasswordType): Promise<UserType> =>
    appApi<UserType, ChangePasswordType>('POST', 'users/password/set', { body: data });

export const resetPassword = (email: string): Promise<{}> =>
    appApi('POST', 'users/password/reset', { body: { email } });
