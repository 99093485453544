// @flow strict
import React, { type Node, useState, useContext, type Context } from 'react';

type SignupEmailContextType = {|
    email: ?string,
    setEmail: (?(((?string) => ?string) | string)) => void,
|};

const SignupEmail: Context<SignupEmailContextType> = React.createContext<SignupEmailContextType>({
    email: undefined,
    setEmail: () => {},
});

type Props = {| children: Node, initialEmail?: string |};

export const SignupEmailProvider = ({ children, initialEmail }: Props): Node => {
    const [email, setEmail] = useState<?string>(initialEmail);

    return (
        <SignupEmail.Provider
            value={{
                email,
                setEmail,
            }}
        >
            {children}
        </SignupEmail.Provider>
    );
};

export const useSignupEmail = (): SignupEmailContextType => useContext(SignupEmail);
