// @flow strict
export type SocialLinkType = { type: string, link: string };

type LinksArrayType = Array<SocialLinkType>;

export const socials: LinksArrayType = [
    { type: 'Facebook', link: '' },
    { type: 'Telegram', link: '' },
    { type: 'Instagram', link: '' },
    { type: 'VK', link: '' },
    { type: 'Artstation', link: '' },
    { type: 'Behance', link: '' },
    { type: 'Twitter', link: '' },
    { type: 'Youtube', link: '' },
];

export type UserDtoType = {|
    username: string,
    birthday?: string | null,
    information?: string,
    avatar?: string,
    socialLinks: LinksArrayType,
|};

export type UserType = {|
    ...UserDtoType,
    id: string,
    email: string,
    avatar?: string,
    unconfirmedEmail?: string,
|};
