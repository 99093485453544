// @flow
import React, { type Node, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import {
    Header,
    NotificationList,
    useLocalizedHistory,
    useQueryParam,
    useLanguage,
    type ProfileDataType,
    PageLayout as PageLayoutUi,
} from '@xyz-school/xyz-react-frontend';

import { api, useCurrentUser, type UserDataType } from '@/model/User';
import urls from '@/constants/urls';

import Footer from './Footer';

import styles from './PageLayout.scss';

type Props = {|
    children: Node,
|};

const getUser = (userData: UserDataType): ProfileDataType | typeof undefined => {
    if (userData.isLogin) {
        const { username, avatar } = userData.user;
        return {
            username,
            avatar,
            roleLabel: '',
            profileLink: urls.PROFILE,
        };
    }
    return undefined;
};

const PageLayout = ({ children }: Props): Node => {
    // TODO need change userType in Header component
    const [next] = useQueryParam('next');
    const { pathname } = useLocation();
    const { toLocalizedRoute } = useLanguage();
    const { replace, push } = useLocalizedHistory();
    const { userData, updateUser } = useCurrentUser();

    const isLoginPage = toLocalizedRoute(pathname) === toLocalizedRoute(urls.LOGIN());

    const login = (): void => {
        replace(urls.LOGIN({ next }));
    };

    const logout = (): void => {
        api.logout().then(() => updateUser());
    };

    const register = (): void => {
        replace(urls.SIGNUP({ next }));
    };

    const customHandleLangChange = useCallback(
        (lang: string) => push(pathname.replace(/^(\/[ru|en]{2})/, `/${lang}`)),
        [pathname, push],
    );

    return (
        <PageLayoutUi
            header={
                <>
                    <Header
                        onClickLogout={logout}
                        onClickRegister={register}
                        user={getUser(userData)}
                        profileLinks={[]}
                        siteName="Network"
                        customHandleLangChange={customHandleLangChange}
                        onClickLogin={!isLoginPage ? login : undefined}
                    />
                    <div className={styles.notificationWrapper}>
                        <NotificationList />
                    </div>
                </>
            }
            footer={<Footer />}
        >
            <main className={styles.main}>{children}</main>
        </PageLayoutUi>
    );
};

export default PageLayout;
