// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@xyz-school/xyz-react-frontend';

import { api, useEmail, useRepeatConfirm, useCurrentUser } from '@/model/User';
import MessageBox from '@/features/MessageBox';
import urls from '@/constants/urls';

const PageConfirmEmail = (): Node => {
    const { t } = useTranslation(['confirmEmail']);
    const { unconfirmedEmail } = useEmail();
    const sendAgain = useRepeatConfirm(api.changeEmailRepeat, t('SENT_AGAIN_NOTIFY'));
    const {
        userData: { isLogin },
    } = useCurrentUser();

    const onSendAgain = async () => {
        if (unconfirmedEmail) {
            await sendAgain(unconfirmedEmail);
        }
    };

    const link = isLogin
        ? {
              url: urls.PROFILE,
              text: t('GO_PROFILE'),
          }
        : {
              url: urls.CHANGE_EMAIL,
              text: t('CHANGE_EMAIL'),
          };

    return (
        <MessageBox.Layout title={t('TITLE')}>
            <MessageBox
                icon={<Icon name="mail" color="text" size="largest" />}
                title={t('TITLE')}
                description={t('EMAIL_TEXT', { email: unconfirmedEmail })}
                button={<MessageBox.Button text={t('SEND_AGAIN')} onClick={onSendAgain} />}
                link={<MessageBox.Link text={link.text} url={link.url} />}
            />
        </MessageBox.Layout>
    );
};

export default PageConfirmEmail;
