// @flow strict
import { useEffect, useRef } from 'react';

/**
 * Декларативный `useInterval`.
 *
 * @param callback - Функция, которая будет вызываться каждые `delay` мс.
 * @param delay - Интервал вызова в миллисекундах. Установите значение `null`, чтобы остановить выполнение.
 */
const useInterval = (callback: () => void | Promise<void>, delay: number | null): void => {
    const savedCallbackRef = useRef();

    useEffect(() => {
        savedCallbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const handler = () => {
            if (typeof savedCallbackRef.current === 'function') {
                savedCallbackRef.current();
            }
        };

        if (typeof delay === 'number' && delay > 0) {
            const id = setInterval(handler, delay);
            return () => clearInterval(id);
        }

        return undefined;
    }, [delay]);
};

export default useInterval;
