// @flow strict
import { useCallback } from 'react';

import { useLocalizedHistory } from '@xyz-school/xyz-react-frontend';

import { isHttpUrl } from '@/core/utils/url';

/**
 * Возвращает функцию, которая редиректит в зависимости от типа {url}.
 *
 * @param url Куда редиректить. Может быть полной ссылкой на другой сайт, либо роутом на текущем.
 * @return Функция, которая выполнит редирект на указанный адрес
 */
const useRedirect = (url: string): (() => void) => {
    const history = useLocalizedHistory();

    return useCallback(() => {
        if (isHttpUrl(url)) {
            window.location.href = url;
        } else {
            history.push(url);
        }
    }, [history, url]);
};

export default useRedirect;
