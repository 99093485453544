// @flow strict
import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { render } from 'react-dom';
import { PageError500 } from '@xyz-school/xyz-react-frontend';

import App from '@/core/App';

const isProd = process.env.NODE_ENV === 'production';

if (isProd) {
    Sentry.init({
        dsn: 'https://f9b32ea8673748e997202fbc2c58cd8b@o287829.ingest.sentry.io/5414503',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: window.location.hostname.startsWith('network-xyz') ? 'prod' : 'stage',
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
        ],
    });
}

const container = document.getElementById('root');
if (container) {
    render(
        <Suspense fallback={<div />}>
            <Sentry.ErrorBoundary fallback={<PageError500 />}>
                <App />
            </Sentry.ErrorBoundary>
        </Suspense>,
        container,
    );
}
