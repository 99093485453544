// @flow
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, PageMetaTags } from '@xyz-school/xyz-react-frontend';

import { type SocialAuthProviderType } from '@/model/User';
import MainLayout, { LayoutHeading } from '@/features/MainLayout';
import urls from '@/constants/urls';
import TextWithActions, { ActionLink } from '@/features/TextWithActions';
import CenteredLayout from '@/features/CenteredLayout';
import SocialAuth from '@/features/SocialAuth';
import useQueryError from '@/features/useQueryError';

import PageLoginForm from './PageLoginForm';
import styles from './PageLogin.scss';

const getAuthErrorMessage = (queryValue: ?string): string => {
    switch (queryValue) {
        case 'email_taken':
            return 'EMAIL_TAKEN';
        default:
            return 'LOGIN_SOCIAL';
    }
};

const PageLogin = (): Node => {
    const { t } = useTranslation(['login']);
    const [next] = useQueryParam('next');
    const [socialAuthError] = useQueryParam('socialAuthError');
    useQueryError('socialAuthError', getAuthErrorMessage(socialAuthError), urls.LOGIN());

    // @todo: move getLink to SocialAuth integrative component.
    const getLink = (provider: SocialAuthProviderType): string => {
        return `/accounts/${provider}/login/${window.location.search}`;
    };

    return (
        <MainLayout>
            <PageMetaTags title={t('TITLE')} />
            <LayoutHeading>{t('TITLE')}</LayoutHeading>
            <CenteredLayout>
                <PageLoginForm next={next} />
                <CenteredLayout.Section label={t('SOCIAL_AUTH')}>
                    <SocialAuth getLink={getLink} />
                </CenteredLayout.Section>
                <TextWithActions
                    text={t('REGISTER_TEXT')}
                    actions={[<ActionLink url={urls.SIGNUP({ next })}>{t('REGISTER_LINK')}</ActionLink>]}
                    className={styles.actions}
                />
            </CenteredLayout>
        </MainLayout>
    );
};

export default PageLogin;
