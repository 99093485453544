// @flow strict
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNotificationActions, NOTIFICATION_TYPE, useLocalizedHistory } from '@xyz-school/xyz-react-frontend';
import { useTranslation } from 'react-i18next';

const useQueryError = (error: string, message: string, fallbackUrl: string = '/') => {
    const { t } = useTranslation(['errors']);
    const location = useLocation();
    const history = useLocalizedHistory();
    const { pushNotification } = useNotificationActions();

    useEffect(() => {
        if (location.search.includes(error)) {
            pushNotification({ type: NOTIFICATION_TYPE.error, message: t(message), name: 'queryError' }, 6000);
            history.push(fallbackUrl);
        }
    }, [location.search, pushNotification, t, error, message, fallbackUrl, history]);
};

export default useQueryError;
