// @flow strict
import React, { type Node, type ElementProps } from 'react';
import classnames from 'classnames';
import { Text } from '@xyz-school/xyz-react-frontend';

import styles from './TextWithActions.scss';

export type TextSizeType = $PropertyType<ElementProps<typeof Text>, 'size'>;

type Props = {|
    text: string,
    actions: Array<Node>,
    className?: string,
    size?: TextSizeType,
|};

const TextWithActions = ({ text, actions, className, size = 'small' }: Props): Node => (
    <div className={classnames(styles.wrapper, className)}>
        <Text className={classnames(styles.item, styles.text)} size={size} tag="span">
            {text}
        </Text>
        <div className={styles.actions}>
            {actions.map((item, index) => (
                /* eslint-disable react/no-array-index-key */
                <div className={styles.item} key={index}>
                    {item}
                </div>
            ))}
        </div>
    </div>
);

export default TextWithActions;
