// @flow strict
import { NOTIFICATION_TYPE, type PushNotificationType } from '@xyz-school/xyz-react-frontend';

import { type ServerErrorsType, getServerError, type EmptyServerErrorsType } from '..';

type GetNoFieldNotifyType = <T: {}>(
    PushNotificationType,
    serverErrors: ServerErrorsType<T>,
) => {
    errors: $Rest<ServerErrorsType<T>, EmptyServerErrorsType>,
    notificationId?: string,
};

// dependency injection
const getNoFieldNotify: GetNoFieldNotifyType = (pushNotification, serverErrors) => {
    const { nonFieldErrors, ...rest } = serverErrors;
    const result = { errors: rest };
    const error = getServerError(nonFieldErrors);

    if (error) {
        return { ...result, notificationId: pushNotification({ type: NOTIFICATION_TYPE.error, message: error }, 0) };
    }
    return result;
};

export default getNoFieldNotify;
