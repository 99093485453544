// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@xyz-school/xyz-react-frontend';

import MessageBox from '@/features/MessageBox';
import urls from '@/constants/urls';

import useRedirectData from './useRedirectData';

const REDIRECT_DELAY = 5; // in seconds

const PageSignupSuccess = (): Node => {
    const { t } = useTranslation(['signupSuccess']);
    const { redirectData, isRegistrationType } = useRedirectData();

    return (
        <MessageBox.Layout>
            <MessageBox
                icon={<Icon name="round-check" color="success" size="largest" />}
                title={isRegistrationType('email') ? t('TITLE_EMAIL') : t('TITLE_SOCIAL')}
                description={t('DESCRIPTION')}
                button={
                    <MessageBox.Button
                        text={redirectData?.title}
                        onClick={redirectData?.redirectUrl}
                        redirectDelay={REDIRECT_DELAY}
                        isService={!!redirectData}
                    />
                }
                link={<MessageBox.Link text={t('PROFILE_LINK')} url={urls.PROFILE} />}
            />
        </MessageBox.Layout>
    );
};

export default PageSignupSuccess;
