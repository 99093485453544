// @flow strict
import { useState } from 'react';

import { type UserType } from '../User';

export type UserDataType =
    | {|
          isLogin: false,
      |}
    | {|
          isLogin: true,
          user: UserType,
      |};

type UserStateType = ?UserType;

export type UseUserStateType = {
    userData: UserDataType,
    setUser: (((UserStateType) => UserStateType) | UserStateType) => void,
};

const useUserState = (initialUser?: UserStateType): UseUserStateType => {
    const [user, setUser] = useState<UserStateType>(initialUser);
    const userData = user ? { isLogin: true, user } : { isLogin: false };
    return {
        userData,
        setUser,
    };
};

export default useUserState;
