// @flow strict
import React, { type Node } from 'react';
import { Input, Button } from '@xyz-school/xyz-react-frontend';
import { useTranslation } from 'react-i18next';

import { type ServerErrorsType, useForm } from '@/core/form';

import styles from './Form.scss';

type FormType = {|
    email: string,
|};

const initialValues: FormType = {
    email: '',
};

export type Props = {|
    onReset: (email: string) => Promise<{}>,
    setEmail: (email: string) => void,
|};

const Form = ({ setEmail, onReset }: Props): Node => {
    const { t } = useTranslation(['resetPassword']);

    const { handleSubmit, handleChange, values, errors, isDisabled } = useForm<FormType>(
        initialValues,
        async (submitValues: FormType, onError) => {
            const { email } = submitValues;
            try {
                await onReset(email);
                setEmail(email);
            } catch (error) {
                const serverErrors: ServerErrorsType<FormType> = error;
                onError(serverErrors);
            }
        },
        ['email'],
    );

    return (
        <form onSubmit={handleSubmit}>
            <Input
                onChange={handleChange}
                value={values.email}
                type="text"
                name="email"
                label="Email"
                className={styles.input}
                errorMessage={errors.email}
                autoComplete="email"
            />
            <Button type="submit" styleType="primary" isFullWidth size="large" isDisabled={isDisabled}>
                {t('SUBMIT')}
            </Button>
        </form>
    );
};

export default Form;
