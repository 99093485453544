// @flow
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, PageMetaTags } from '@xyz-school/xyz-react-frontend';

import { type SocialAuthProviderType, useFeatures } from '@/model/User';
import urls from '@/constants/urls';
import MainLayout, { LayoutHeading } from '@/features/MainLayout';
import TextWithActions, { ActionLink } from '@/features/TextWithActions';
import CenteredLayout from '@/features/CenteredLayout';
import SocialAuth from '@/features/SocialAuth';

import PageSignupForm from './PageSignupForm';

import styles from './PageSignup.scss';

const PageSignup = (): Node => {
    const { t } = useTranslation(['signup']);
    const [next] = useQueryParam('next');
    const features = useFeatures();

    // @todo: move getLink to SocialAuth integrative component.
    const getLink = (provider: SocialAuthProviderType): string => {
        return `/accounts/${provider}/login/${window.location.search}`;
    };

    return (
        <MainLayout>
            <PageMetaTags title={t('TITLE')} />
            <LayoutHeading>{t('TITLE')}</LayoutHeading>
            <CenteredLayout>
                <PageSignupForm className={styles.form} />
                {features.socialNetworkRegistration && (
                    <CenteredLayout.Section label={t('SOCIAL_AUTH')}>
                        <SocialAuth getLink={getLink} />
                    </CenteredLayout.Section>
                )}
                <TextWithActions
                    text={t('HINT_TEXT')}
                    actions={[<ActionLink url={urls.LOGIN({ next })}>{t('HINT_ACTION')}</ActionLink>]}
                    className={styles.actions}
                />
            </CenteredLayout>
        </MainLayout>
    );
};

export default PageSignup;
