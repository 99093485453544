// @flow strict
import React, { type Node } from 'react';
import { Text } from '@xyz-school/xyz-react-frontend';

import styles from './CenteredLayout.scss';

type Props = {|
    label: string,
    children: Node,
|};

const Section = ({ children, label }: Props): Node => {
    return (
        <div className={styles.section}>
            <Text color="label" size="small" className={styles.section__label}>
                {label}
            </Text>
            {children}
        </div>
    );
};

export default Section;
