// @flow strict

/**
 * Сервер всегда возвращает нам строку, так как значение фичи может быть
 * - Строка: theme: 'dark'
 * - boolean: beta: 'true'
 * для удобства boolean, мы обрабатываем
 */
export const normalizeFeatureValue = (value: string): string | boolean => {
    if (value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }
    return value;
};
