// @flow strict
import { api as appApi } from '@/core/api';
import type { AppRedirectData } from '@/core/types';

import { type UserType } from '../User';

export type SetEmailDTO = {| email: string, password1: string, password2: string |};

export const setEmail = (data: SetEmailDTO): Promise<{}> =>
    appApi<{}, SetEmailDTO>('POST', 'users/email/set', { body: data });

type ChangeEmailType = {| email: string, newEmail: string, password: string |};

export const changeEmail = (data: ChangeEmailType): Promise<{}> =>
    appApi<{}, ChangeEmailType>('PUT', 'users/email', { body: data });

export const deleteChangeEmail = (email: string): Promise<{}> =>
    appApi<{}, {}>('DELETE', `users/email`, { query: { email } });

export type ChangeEmailRepeatType = (email: string) => Promise<{}>;

export const changeEmailRepeat: ChangeEmailRepeatType = (email) =>
    appApi<{}, { email: string }>('POST', 'users/email/resend', { body: { email } });

export type ConfirmEmailType = (data: { key: string }) => Promise<{
    application?: AppRedirectData,
    user?: UserType,
}>;

export const confirmEmail: ConfirmEmailType = (data) => appApi('POST', 'users/email/confirm', { body: data });
