// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Input,
    useNotificationActions,
    NOTIFICATION_TYPE,
    useLocalizedHistory,
    PageMetaTags,
} from '@xyz-school/xyz-react-frontend';

import { api, useEmail } from '@/model/User';
import MainLayout, { LayoutHeading } from '@/features/MainLayout';
import ActionButtons from '@/features/ActionButtons';
import CenteredLayout from '@/features/CenteredLayout';
import urls from '@/constants/urls';
import { type ServerErrorsType, useForm } from '@/core/form';

import styles from './PageChangeEmail.scss';

type FormType = {|
    newEmail: string,
    password: string,
|};

const initialValues: FormType = {
    newEmail: '',
    password: '',
};

const PageChangeEmail = (): Node => {
    const { t } = useTranslation(['changeEmail', 'profile']);
    const { pushNotification } = useNotificationActions();
    const history = useLocalizedHistory();
    const { email, unconfirmedEmail, setNewEmail } = useEmail();

    const existedEmail = email || unconfirmedEmail;

    const { handleSubmit, handleChange, values, errors, isDisabled } = useForm<FormType>(
        initialValues,
        async (submitValues: FormType, onError) => {
            if (existedEmail)
                try {
                    await api.changeEmail({ ...submitValues, email: existedEmail });
                    setNewEmail(submitValues.newEmail);
                    pushNotification({ type: NOTIFICATION_TYPE.success, message: t('profile:SAVED') });
                    history.push(urls.CONFIRM_EMAIL);
                } catch (error) {
                    const serverErrors: ServerErrorsType<FormType> = error;
                    onError(serverErrors);
                }
        },
        ['newEmail', 'password'],
    );

    const onCancel = (): void => {
        history.goBack();
    };

    return (
        <MainLayout>
            <PageMetaTags title={t('TITLE')} />
            <LayoutHeading>{t('TITLE')}</LayoutHeading>
            <CenteredLayout>
                <form onSubmit={handleSubmit}>
                    <Input
                        label={t('EMAIL_PLACEHOLDER')}
                        className={styles.email}
                        name="newEmail"
                        type="text"
                        value={values.newEmail}
                        onChange={handleChange}
                        errorMessage={errors.newEmail}
                        autoComplete="email"
                    />
                    <Input
                        label={t('PASSWORD_PLACEHOLDER')}
                        className={styles.password}
                        name="password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        errorMessage={errors.password}
                        autoComplete="current-password"
                    />
                    <ActionButtons onCancel={onCancel} isDisabled={isDisabled} />
                </form>
            </CenteredLayout>
        </MainLayout>
    );
};

export default PageChangeEmail;
