// @flow strict

type ButtonPropsReturnType = {| linkTo: string, linkTarget: string |} | {| onClick: () => void | Promise<void> |};

export const getButtonProps = (onClick: (() => void | Promise<void>) | string): ?ButtonPropsReturnType => {
    if (typeof onClick === 'string') {
        return {
            linkTo: onClick,
            linkTarget: '_blank',
        };
    }

    if (typeof onClick === 'function') {
        return { onClick };
    }

    return undefined;
};
