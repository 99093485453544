// @flow strict
import React, { type Node } from 'react';
import classNames from 'classnames';

import { type SocialAuthProviderType, type SocialConnectionsType, useCurrentUser } from '@/model/User';
import SocialAuthButton from '@/ui/SocialAuthButton';

import styles from './SocialAuthUi.scss';

export type Props = {
    className?: string,
    buttonsData: SocialConnectionsType,
    onAuth: (provider: SocialAuthProviderType) => void,
    onDelete: (provider: SocialAuthProviderType) => void | Promise<void>,
};

const SocialAuthUi = ({ className, buttonsData, onAuth, onDelete }: Props): Node => {
    const {
        userData: { isLogin },
    } = useCurrentUser();

    return (
        <div className={classNames(styles.socialAuth, className)}>
            {buttonsData.map(({ provider, isConnected }) => (
                <div key={provider} className={styles.buttonWrapper}>
                    <SocialAuthButton
                        provider={provider}
                        onClick={() => onAuth(provider)}
                        onDelete={isLogin && isConnected ? () => onDelete(provider) : undefined}
                        disconnected={isLogin && !isConnected}
                    />
                </div>
            ))}
        </div>
    );
};

export default SocialAuthUi;
