// @flow
import React, { type Node } from 'react';
import { Grid } from '@xyz-school/xyz-react-frontend';

import styles from './MainLayout.scss';

type Props = {|
    children: Node,
|};

const MainLayout = ({ children }: Props): Node => (
    <Grid.Container className={styles.wrapper}>{children}</Grid.Container>
);

export default MainLayout;
