// @flow strict
import { useEffect, useRef, useState } from 'react';

import useInterval from '../useInterval';

export type DelayType = number | null;
export type CallbackType = () => void | Promise<void>;

/**
 * Отложенное выполнение коллбэка, с задержкой в {delay} секунд.
 * Обновляется 1 раз в секунду, уменьшая значение {delay} на единицу.
 *
 * @param callback Функция, которая будет вызвана после заданной задержки.
 * @param delay Задержка перед выполнением коллбэка в секундах.
 *              При значении `null` не будет запускаться таймер и коллбэк не выполнится.
 * @return Оставшееся время таймера
 */
const useDelay = (callback: CallbackType, delay: DelayType = null): DelayType => {
    const savedCallbackRef = useRef();
    const [timeLeft, setTimeLeft] = useState<DelayType>(delay);

    const updateTimer = () => {
        setTimeLeft((time: DelayType): DelayType => {
            return time !== null && time > 0 ? time - 1 : time;
        });
    };

    useEffect(() => {
        if (typeof timeLeft === 'number' && timeLeft < 0) {
            setTimeLeft(0);
        }
    }, [timeLeft]);

    useEffect(() => {
        savedCallbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (timeLeft === 0 && typeof savedCallbackRef.current === 'function') {
            savedCallbackRef.current();
        }
    }, [timeLeft]);

    // если `timeLeft === null`, то setInterval запускать не нужно
    useInterval(updateTimer, timeLeft !== null ? 1000 : null);

    return timeLeft;
};

export default useDelay;
