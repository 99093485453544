// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { PageMetaTags } from '@xyz-school/xyz-react-frontend';

import MainLayout, { LayoutHeading } from '@/features/MainLayout';
import CenteredLayout from '@/features/CenteredLayout';

import Form from './Form';

const PageSetEmail = (): Node => {
    const { t } = useTranslation(['setEmail']);

    return (
        <MainLayout>
            <PageMetaTags title={t('TITLE')} />
            <LayoutHeading>{t('TITLE')}</LayoutHeading>
            <CenteredLayout>
                <Form />
            </CenteredLayout>
        </MainLayout>
    );
};

export default PageSetEmail;
