// @flow strict
import React, { type Node, type AbstractComponent, useState } from 'react';
import { PhotoUploader } from '@xyz-school/xyz-react-frontend';

import { api, useCurrentUser, withUser } from '@/model/User';

import styles from './Photo.scss';

type Props = {||};

const MIN_AVATAR_WIDTH = 346;
const MIN_AVATAR_HEIGHT = 346;

const ProfilePhoto: AbstractComponent<Props> = withUser<Props>(({ user }): Node => {
    const { updateUser } = useCurrentUser();
    const [error, setError] = useState(false);

    const handleChangeAvatar = (blob: Blob) => {
        api.uploadAvatar(blob).then(({ avatar }) => {
            updateUser((oldUser) => {
                if (oldUser) {
                    return { ...oldUser, avatar };
                }
            });
        });
        setError(false);
    };

    const handleDeleteAvatar = () => {
        api.deleteAvatar().then(() => {
            updateUser((oldUser) => {
                if (oldUser) {
                    const { avatar, ...userWithoutAvatar } = oldUser;
                    return userWithoutAvatar;
                }
            });
        });
        setError(false);
    };

    return (
        <PhotoUploader
            src={user.avatar}
            className={styles.uploader}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
            minWidth={MIN_AVATAR_WIDTH}
            minHeight={MIN_AVATAR_HEIGHT}
            onError={setError}
            error={error}
            isEditable
        />
    );
});

export default ProfilePhoto;
