// @flow strict
import React, { type Node } from 'react';
import classNames from 'classnames';
import type { SocialAuthProviderType } from '@/model/User';

import { Icon } from '@xyz-school/xyz-react-frontend';
import styles from './SocialAuthButton.scss';

export type Props = {|
    provider: SocialAuthProviderType,
    onClick: () => void,
    onDelete?: () => void | Promise<void>,
    disconnected?: boolean,
|};

const SocialAuthButton = ({ provider, onDelete, onClick, disconnected }: Props): Node => {
    const icon = <Icon name={provider} color="text-inverted" size="extra-large" />;

    return (
        <div className={classNames(styles.wrapper, styles[provider], { [styles.disconnected]: disconnected })}>
            {onDelete ? (
                <>
                    <div className={styles.iconWrapper}>{icon}</div>
                    <button type="button" className={classNames(styles.delete)} onClick={onDelete}>
                        <Icon name="close" color="text-inverted" />
                    </button>
                </>
            ) : (
                <button className={styles.button} type="button" onClick={onClick}>
                    {icon}
                </button>
            )}
        </div>
    );
};

export default SocialAuthButton;
