// @flow strict
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useNotificationActions, NOTIFICATION_TYPE, useLocalizedHistory } from '@xyz-school/xyz-react-frontend';

import { useCurrentUser, type ConfirmEmailType } from '@/model/User';
import type { AppRedirectData } from '@/core/types';

type ReturnType = {| app: ?AppRedirectData, isConfirmed: boolean |};

const useConfirm = (api: ConfirmEmailType): ReturnType => {
    const { push } = useLocalizedHistory();
    const { key } = useParams();
    const {
        updateUser,
        userData: { isLogin },
    } = useCurrentUser();
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [app, setApp] = useState<AppRedirectData | typeof undefined>();
    const { pushNotification } = useNotificationActions();

    useEffect(() => {
        if (key && !isConfirmed && !app) {
            api({ key })
                .then(({ user, application }) => {
                    if (!isLogin && user) {
                        updateUser(user);
                        if (application) {
                            setApp(application);
                        }
                    }
                    setIsConfirmed(true);
                })
                .catch((error) => {
                    if (error.detail) {
                        pushNotification({ type: NOTIFICATION_TYPE.error, message: error.detail });
                        push('/');
                    }
                });
        }
    }, [key, api, updateUser, isLogin, push, isConfirmed, app, pushNotification]);

    return { app, isConfirmed };
};

export default useConfirm;
