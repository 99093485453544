// @flow strict
import React, { type Node, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
    Button,
    Container,
    Heading,
    Text,
    GridRow,
    GridCol,
    Separator,
    Modal,
    useMedia,
} from '@xyz-school/xyz-react-frontend';

import WelcomeScreenLayout from './WelcomeScreenLayout';
import GridLayout from './GridLayout';
import ProjectCard from './ProjectCard';
import FeedbackForm from './FeedbackForm';
import FeedbackModalHeader from './FeedbackModalHeader';

import leftBackShape from './assets/images/leftBackShape.png';
import leftFrontShape from './assets/images/leftFrontShape.png';
import rightFrontShape from './assets/images/rightFrontShape.png';
import rightBackShape from './assets/images/rightBackShape.png';

import keyboard from './assets/images/keyboard.png';
import mouse from './assets/images/mouse.png';
import micro from './assets/images/micro.png';

import mygamesPartnerLogo from './assets/images/mygames.png';
import sperasoftPartnerLogo from './assets/images/sperasoft.png';
import digitalformsPartnerLogo from './assets/images/digitalforms.png';

import blacksteinnPartnerLogo from './assets/images/blacksteinn.png';
import tracePartnerLogo from './assets/images/trace.png';
import octoboxPartnerLogo from './assets/images/octobox.png';

import styles from './PageHome.scss';

const PageHome = (): Node => {
    const { t } = useTranslation(['home']);
    const media = useMedia();
    const [isModalOpened, setModalOpened] = useState<boolean>(false);
    const openModal = (): void => setModalOpened(true);
    const closeModal = (): void => setModalOpened(false);

    const serviceBrand = 'XYZ';
    const serviceName = 'Network';
    const projects = [
        {
            slug: 'school',
            title: t('PROJECTS_SCHOOL_TITLE'),
            description: t('PROJECTS_SCHOOL_DESCRIPTION'),
            link: 'https://school-xyz.com/',
        },
        {
            slug: 'media',
            title: t('PROJECTS_MEDIA_TITLE'),
            description: t('PROJECTS_MEDIA_DESCRIPTION'),
            link: 'https://media-xyz.com/ru',
        },
        {
            slug: 'production',
            title: t('PROJECTS_PRODUCTION_TITLE'),
            description: t('PROJECTS_PRODUCTION_DESCRIPTION'),
            label: t('PROJECTS_PRODUCTION_LABEL'),
        },
    ];

    const modalHeader = media.sm || media.xs ? <FeedbackModalHeader onClose={closeModal} /> : undefined;

    return (
        <div className={styles.pageHome}>
            <img alt="" src={leftBackShape} className={styles.leftBackShape} />
            <img alt="" src={leftFrontShape} className={styles.leftFrontShape} />
            <img alt="" src={rightBackShape} className={styles.rightBackShape} />
            <img alt="" src={rightFrontShape} className={styles.rightFrontShape} />

            <div className={styles.welcomeScreenWrap}>
                <WelcomeScreenLayout
                    midBlockContent={
                        <>
                            <GridRow>
                                <GridCol xl={7} lg={7} sm={2} xs={3}>
                                    <h1 className={styles.mainHeading}>
                                        {serviceBrand.split('').map((item) => (
                                            <span>{item}</span>
                                        ))}
                                        <br />
                                        <span className={styles.accent}>
                                            {serviceName.split('').map((item) => (
                                                <span>{item}</span>
                                            ))}
                                        </span>
                                    </h1>
                                </GridCol>
                            </GridRow>
                            <Container marginTop={4}>
                                <GridRow>
                                    <GridCol xl={2} lg={3} sm={1} xs={2}>
                                        <Text>{t('PAGE_HOME_DESCRIPTION')}</Text>
                                    </GridCol>
                                </GridRow>
                            </Container>
                        </>
                    }
                    botBlockContent={
                        <GridRow>
                            <GridCol xl={4} sm={2} xs={4}>
                                <Container marginTopXs={4} className={styles.row}>
                                    <img alt={t('PAGE_HOME_TELL')} src={micro} className={styles.aboutImage} />
                                    <Text className={styles.aboutText}>{t('PAGE_HOME_TELL')}</Text>
                                </Container>
                            </GridCol>
                            <GridCol xl={4} sm={2} xs={4}>
                                <Container marginTopXs={4} className={styles.row}>
                                    <img alt={t('PAGE_HOME_LEARN_MAKE')} src={mouse} className={styles.aboutImage} />
                                    <Text className={styles.aboutText}>{t('PAGE_HOME_LEARN_MAKE')}</Text>
                                </Container>
                            </GridCol>
                            <GridCol xl={4} sm={4} xs={4}>
                                <Container marginTopSm={5} marginTopXs={4} className={styles.row}>
                                    <img
                                        alt={t('PAGE_HOME_CREATE_OURSELVES')}
                                        src={keyboard}
                                        className={styles.aboutImage}
                                    />
                                    <Text className={styles.aboutText}>{t('PAGE_HOME_CREATE_OURSELVES')}</Text>
                                </Container>
                            </GridCol>
                        </GridRow>
                    }
                />
            </div>
            <Container marginTop={20} marginTopSm={12} marginTopXs={8}>
                <GridLayout
                    leftSideContent={
                        <Container marginBottom={3}>
                            <GridRow>
                                <GridCol xl={2} lg={3} sm={4}>
                                    <Heading level={1}>{t('PAGE_HOME_OUR_PROJECTS_TITLE')}</Heading>
                                </GridCol>
                            </GridRow>
                        </Container>
                    }
                    rightSideContent={
                        <>
                            {projects.map((item) => (
                                <ProjectCard
                                    key={item.slug}
                                    description={item.description}
                                    label={item.label && item.label}
                                    title={item.title}
                                    link={item.link || undefined}
                                />
                            ))}
                        </>
                    }
                />
            </Container>

            <div className={styles.about}>
                <GridLayout
                    leftSideContent={
                        <Container marginBottom={3}>
                            <GridRow>
                                <GridCol xl={2} lg={3} sm={4}>
                                    <Heading level={1}>{t('PAGE_HOME_ABOUT_XYZ_NETWORK_TITLE')}</Heading>
                                </GridCol>
                            </GridRow>
                        </Container>
                    }
                    rightSideContent={<Heading level={2}>{t('PAGE_HOME_ABOUT_XYZ_NETWORK_DESCRIPTION')}</Heading>}
                />
            </div>

            <div className={styles.partners}>
                <GridLayout
                    leftSideContent={
                        <Container marginBottom={3}>
                            <GridRow>
                                <GridCol xl={2} lg={3} sm={4}>
                                    <Heading level={1}>{t('PAGE_HOME_OUR_PARTNERS_TITLE')}</Heading>
                                </GridCol>
                            </GridRow>
                        </Container>
                    }
                    rightSideContent={
                        <>
                            <Separator marginTop={0} marginBottom={10} marginBottomSm={8} marginBottomXs={4} />
                            <div className={classNames(styles.row, styles.spaceBetween)}>
                                <img alt="" src={mygamesPartnerLogo} className={styles.partnerLogo} />
                                <img alt="" src={sperasoftPartnerLogo} className={styles.partnerLogo} />
                            </div>
                            <Separator
                                marginTop={10}
                                marginTopSm={8}
                                marginTopXs={4}
                                marginBottom={10}
                                marginBottomSm={8}
                                marginBottomXs={4}
                            />
                            <div className={classNames(styles.row, styles.spaceBetween)}>
                                <img alt="" src={digitalformsPartnerLogo} className={styles.partnerLogo} />
                                <img alt="" src={blacksteinnPartnerLogo} className={styles.partnerLogo} />
                            </div>
                            <Separator
                                marginTop={10}
                                marginTopSm={8}
                                marginTopXs={4}
                                marginBottom={10}
                                marginBottomSm={8}
                                marginBottomXs={4}
                            />
                            <div className={classNames(styles.row, styles.spaceBetween)}>
                                <img alt="" src={tracePartnerLogo} className={styles.partnerLogo} />
                                <img alt="" src={octoboxPartnerLogo} className={styles.partnerLogo} />
                            </div>
                        </>
                    }
                />
            </div>

            <div className={styles.contactUs}>
                <GridLayout
                    leftSideContent={
                        <Container marginBottom={3}>
                            <GridRow>
                                <GridCol xl={2} lg={3} sm={2} xs={2}>
                                    <Heading level={1} color="text-inverted">
                                        {t('PAGE_HOME_FEEDBACK_TITLE')}
                                    </Heading>
                                </GridCol>
                            </GridRow>
                        </Container>
                    }
                    rightSideContent={
                        <>
                            <Heading level={2} color="text-inverted">
                                {t('PAGE_HOME_FEEDBACK_DESCRIPTION')}
                            </Heading>
                            <Container marginTop={4} className="theme-dark">
                                <Button styleType="primary" onClick={openModal} className={styles.btn}>
                                    {t('PAGE_HOME_FEEDBACK_BUTTON_TEXT')}
                                </Button>
                            </Container>
                        </>
                    }
                />
            </div>
            <Modal onClose={closeModal} isOpened={isModalOpened} header={modalHeader} showCloseButton>
                <Container
                    marginTop={6}
                    marginRight={6}
                    marginBottom={6}
                    marginLeft={6}
                    marginTopSm={6}
                    marginRightSm={1.5}
                    marginBottomSm={6}
                    marginLeftSm={1.5}
                    className={styles.feedbackForm}
                >
                    <FeedbackForm onClose={closeModal} />
                </Container>
            </Modal>
        </div>
    );
};

export default PageHome;
