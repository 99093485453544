// @flow strict

import qs from 'qs';

type UrlWithQuery<T> = (?T) => string;

const getUrlWithQuery = <T: {} = {}>(str: string): UrlWithQuery<T> => (params) =>
    params ? `${str}?${qs.stringify(params)}` : str;

type NextType = { next?: ?string };

type UrlsType = {
    PAGE_HOME: string,
    LOGIN: UrlWithQuery<NextType>,
    SIGNUP: UrlWithQuery<NextType>,
    INFO: (slug: string) => string,
    RESET: string,
    PROFILE: string,
    SET_EMAIL: string,
    SIGNUP_SUCCESS: string,
    CHANGE_EMAIL: string,
    CONFIRM_EMAIL: string,
};

const urls: UrlsType = {
    PAGE_HOME: '/',
    LOGIN: getUrlWithQuery('/login/'),
    SIGNUP: getUrlWithQuery('/signup/'),
    INFO: (slug) => `/info/${slug}`,
    RESET: '/reset-password/',
    PROFILE: '/profile/',
    SET_EMAIL: '/set-email/',
    SIGNUP_SUCCESS: '/signup-success/',
    CHANGE_EMAIL: '/change-email/',
    CONFIRM_EMAIL: '/confirm-email/',
};

export default urls;
