// @flow strict
import React, { type Node } from 'react';

import { GridContainer, GridCol, GridRow } from '@xyz-school/xyz-react-frontend';

type Props = {|
    leftSideContent?: Node,
    rightSideContent?: Node,
|};

const GridLayout = ({ leftSideContent, rightSideContent }: Props): Node => {
    return (
        <GridContainer>
            <GridRow>
                <GridCol xl={4} sm={4}>
                    {leftSideContent}
                </GridCol>
                <GridCol xl={8} sm={4}>
                    {rightSideContent}
                </GridCol>
            </GridRow>
        </GridContainer>
    );
};

export default GridLayout;
