// @flow strict

/*
 * Проверяет, является ли переданная строка ссылкой на внешний ресурс.
 */
export const isHttpUrl = (url: string): boolean => {
    let item;

    try {
        item = new URL(url);
    } catch (_) {
        return false;
    }

    return item.protocol === 'http:' || item.protocol === 'https:';
};
