// @flow strict
import { useCallback, useState } from 'react';

import { useLanguage } from '@xyz-school/xyz-react-frontend';

import useLocalStorage from '@/core/hooks/useLocalStorage';
import { api as userApi } from '@/model/User';
import { type UserSettingsType, EMPTY_USER_SETTINGS, STORAGE_SETTINGS_KEY } from '@/model/User/UserSettings';

type SettingsDataType = {|
    settings: UserSettingsType,
    isLoading: boolean,
    isError: boolean,
|};

type UserLoadSettingsReturnType = [SettingsDataType, { loadSettings: () => void }];

const useLoadSettings = (): UserLoadSettingsReturnType => {
    const { currentLang } = useLanguage();
    const [settingsInStorage, updateSettingsInStorage] = useLocalStorage<UserSettingsType>(
        `${STORAGE_SETTINGS_KEY}_${currentLang}`,
    );
    const [localSettingsData, updateLocalSettingsData] = useState<SettingsDataType>({
        settings: EMPTY_USER_SETTINGS,
        isLoading: true,
        isError: false,
    });

    const loadSettings = useCallback(() => {
        userApi
            .getCurrentUserSettings()
            .then((data) => {
                updateLocalSettingsData({
                    settings: data,
                    isLoading: false,
                    isError: false,
                });
                updateSettingsInStorage(data);
            })
            .catch(() => {
                updateSettingsInStorage();
                updateLocalSettingsData((oldData) => ({ ...oldData, isLoading: false, isError: true }));
            });
    }, [updateSettingsInStorage, updateLocalSettingsData]);

    return [
        {
            settings: settingsInStorage || localSettingsData.settings,
            isLoading: localSettingsData.isLoading,
            isError: localSettingsData.isError,
        },
        {
            loadSettings,
        },
    ];
};

export default useLoadSettings;
