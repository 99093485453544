// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import TextWithActions, { ActionLink } from '@/features/TextWithActions';

import urls from '@/constants/urls';

type Props = {|
    email: string,
|};

const UnconfirmedEmailActions = ({ email }: Props): Node => {
    const { t } = useTranslation(['profile']);

    const changeEmail = (
        <ActionLink url={urls.CHANGE_EMAIL} size="regular">
            {t('AUTH_CHANGE')}
        </ActionLink>
    );

    return <TextWithActions actions={[changeEmail]} text={email} size="regular" />;
};

export default UnconfirmedEmailActions;
