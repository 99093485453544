// @flow strict
import React, { type Node } from 'react';

import { Link, Text } from '@xyz-school/xyz-react-frontend';
import commonStyles from '@/core/styles.scss';

import { type TextSizeType } from '.';

type Props = {|
    children: string,
    url: string,
    size?: TextSizeType,
|};

const ActionLink = ({ children, url, size = 'small' }: Props): Node => (
    <Link to={url} className={commonStyles.link}>
        <Text size={size} tag="span" color="inherit">
            {children}
        </Text>
    </Link>
);

export default ActionLink;
