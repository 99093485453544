// @flow
import React, { type Node } from 'react';
import * as Sentry from '@sentry/react';
import {
    IconLoader,
    NotificationProvider,
    I18nProvider,
    LocalizedRouter,
    MediaContextProvider,
    AppMetaTags,
    PageError500,
} from '@xyz-school/xyz-react-frontend';

import { CurrentUserProvider, SignupEmailProvider } from '@/model/User';

import PageLayout from './PageLayout';
import Routes from './Routes';
import './App.scss';

const SUPPORTED_LANG = ['en', 'ru'];
const DEFAULT_LANG = SUPPORTED_LANG[1];

const appName = 'Network';

const description = '';

const App = (): Node => (
    <I18nProvider
        supportedLngs={SUPPORTED_LANG}
        fallbackLng={DEFAULT_LANG}
        loadPath={`/locales/{{lng}}/{{ns}}.json?v=${__BUILD__}`}
        useSuspense={false}
        version={`${__BUILD__}`}
    >
        <NotificationProvider>
            <IconLoader />
            <LocalizedRouter>
                <AppMetaTags appName={appName} description={description} />
                <MediaContextProvider>
                    <SignupEmailProvider>
                        <CurrentUserProvider>
                            <PageLayout>
                                <Sentry.ErrorBoundary fallback={<PageError500 />}>
                                    <Routes />
                                </Sentry.ErrorBoundary>
                            </PageLayout>
                        </CurrentUserProvider>
                    </SignupEmailProvider>
                </MediaContextProvider>
            </LocalizedRouter>
        </NotificationProvider>
    </I18nProvider>
);

export default App;
