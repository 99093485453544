// @flow
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Icon, Heading, Text, NotificationList } from '@xyz-school/xyz-react-frontend';
import { type ProfileItemsType } from '@/model/Profile';

import styles from './ModalHeader.scss';

export type Props = {|
    onCancel: () => void,
    currentSection: ProfileItemsType,
|};

const ModalHeader = ({ onCancel, currentSection }: Props): Node => {
    const { t } = useTranslation(['profile']);

    return (
        <div className={styles.wrapper}>
            <header className={styles.header}>
                <button type="button" onClick={onCancel} className={classnames(styles.button, styles.arrow)}>
                    <Icon name="caret-right" color="text" />
                </button>
                <Heading level={3}>{t(currentSection)}</Heading>
                <button type="button" onClick={onCancel} className={classnames(styles.button, styles.cancel)}>
                    <Text size="small" color="label">
                        {t('CANCEL')}
                    </Text>
                </button>
            </header>
            <NotificationList />
        </div>
    );
};

export default ModalHeader;
