// @flow strict
import React, { type Node, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Input, Button, Heading, Icon, Text } from '@xyz-school/xyz-react-frontend';

import { type ServerErrorsType, useForm } from '@/core/form';
import { api as appApi } from '@/core/api';

type FeedbackFormType = {|
    name?: string,
    email: string,
    text: string,
|};

const initialValues: FeedbackFormType = {
    email: '',
    text: '',
};

type Props = {|
    onClose: () => void,
|};

const FeedbackForm = ({ onClose }: Props): Node => {
    const { t } = useTranslation(['home']);
    const [isSent, setIsSent] = useState<boolean>(false);

    const { handleSubmit, handleChange, values, errors, isDisabled } = useForm<FeedbackFormType>(
        initialValues,
        async (submitValues: FeedbackFormType, onError, resetForm) => {
            try {
                await appApi<{}, FeedbackFormType>('POST', 'feedback', { body: submitValues });
                resetForm();
                setIsSent(true);
            } catch (error) {
                const serverErrors: ServerErrorsType<FeedbackFormType> = error;
                onError(serverErrors);
            }
        },
        ['email', 'text'],
    );

    // The email was sent successfully
    if (isSent) {
        return (
            <>
                <Container marginBottom={8} marginBottomSm={6} textAlign="center">
                    <Heading level={1}>{t('FEEDBACK_FORM_SUCCESS_TITLE')}</Heading>
                </Container>

                <Container marginBottom={2} textAlign="center">
                    <Icon name="round-check" color="success" size="largest" />
                </Container>

                <Container marginBottom={10} marginBottomSm={7}>
                    <Text align="center">{t('FEEDBACK_FORM_SUCCESS_MESSAGE')}</Text>
                </Container>

                <Button
                    onClick={onClose}
                    type="button"
                    styleType="primary"
                    size="large"
                    isDisabled={isDisabled}
                    isFullWidth
                >
                    {t('FEEDBACK_FORM_SUCCESS_BUTTON_TEXT')}
                </Button>
            </>
        );
    }

    // Feedback form
    return (
        <>
            <Container textAlign="center" marginBottom={4}>
                <Heading level={1}>{t('FEEDBACK_FORM_TITLE')}</Heading>
            </Container>

            <form onSubmit={handleSubmit}>
                <Container marginBottom={2} marginBottomSm={1.5}>
                    <Input
                        onChange={handleChange}
                        value={values.name}
                        type="text"
                        name="name"
                        label={t('FEEDBACK_FORM_NAME')}
                        errorMessage={errors.name}
                        autoComplete="name"
                    />
                </Container>

                <Container marginBottom={2} marginBottomSm={1.5}>
                    <Input
                        onChange={handleChange}
                        value={values.email}
                        type="text"
                        name="email"
                        label={t('FEEDBACK_FORM_EMAIL')}
                        errorMessage={errors.email}
                        autoComplete="email"
                    />
                </Container>

                <Container marginBottom={3}>
                    <Input
                        onChange={handleChange}
                        value={values.text}
                        type="textarea"
                        name="text"
                        label={t('FEEDBACK_FORM_TEXT')}
                        errorMessage={errors.text}
                        autoComplete="off"
                    />
                </Container>

                <Button type="submit" styleType="primary" size="large" isDisabled={isDisabled} isFullWidth>
                    {t('FEEDBACK_FORM_SUBMIT_BUTTON_TEXT')}
                </Button>
            </form>
        </>
    );
};

export default FeedbackForm;
