// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@xyz-school/xyz-react-frontend';

import { useCurrentUser } from '@/model/User';
import urls from '@/constants/urls';
import useRedirect from '@/core/hooks/useRedirect';
import useDelay from '@/core/hooks/useDelay';

import { getButtonProps } from './helpers';

type Props = {|
    text?: string,
    onClick?: (() => void | Promise<void>) | string,
    redirectDelay?: number | null,
    isService?: boolean,
    isUnauthorizedAllowed?: boolean,
|};

const MessageBoxButton = ({
    text,
    onClick,
    redirectDelay = null,
    isService = false,
    isUnauthorizedAllowed = false,
}: Props): Node => {
    const { t } = useTranslation(['messageBox']);
    const { userData } = useCurrentUser();

    const redirectUrl = onClick && typeof onClick === 'string' ? onClick : urls.PAGE_HOME;
    const handleRedirect = useRedirect(redirectUrl);
    const timeLeft = useDelay(handleRedirect, redirectDelay);

    const getButtonWithTimer = (label: string): string => {
        if (!timeLeft) {
            return label;
        }
        return `${label} ${timeLeft} ${t('SECONDS')}`;
    };

    let data;

    if (!isUnauthorizedAllowed && !userData.isLogin) {
        data = {
            text: getButtonWithTimer(t('LOGIN')),
            onClick: urls.LOGIN(),
        };
    } else if (!!text && !!onClick) {
        data = {
            text: getButtonWithTimer(isService ? t('BACK_TO_SERVICE', { text }) : text),
            onClick,
        };
    } else {
        data = {
            text: getButtonWithTimer(t('GO_HOME')),
            onClick: urls.PAGE_HOME,
        };
    }

    return (
        <Button {...getButtonProps(data.onClick)} styleType="primary" size="large" isFullWidth>
            {data.text}
        </Button>
    );
};

export default MessageBoxButton;
