// @flow
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Text, Link, Copyright } from '@xyz-school/xyz-react-frontend';
import urls from '@/constants/urls';

import styles from './Footer.scss';

const Footer = (): Node => {
    const { t } = useTranslation(['footer']);
    return (
        <footer className={styles.footer}>
            <Grid.Container className={styles.content}>
                <Grid.Row>
                    <Grid.Item xl={2} sm={2} xs={4} className={styles.gridItem}>
                        <Link to={urls.INFO('about')}>
                            <Text size="small">{t('ABOUT')}</Text>
                        </Link>
                    </Grid.Item>
                    <Grid.Item xl={2} sm={2} xs={4} className={styles.gridItem}>
                        <Link
                            to="https://www.school-xyz.com/partners?utm_source=network_xyz&utm_medium=referal&utm_campaign=partners_program"
                            target="_blank"
                        >
                            <Text size="small">{t('PARTNERS')}</Text>
                        </Link>
                    </Grid.Item>
                    <Grid.Item xl={2} sm={2} xs={4} className={styles.gridItem}>
                        <Link to={urls.INFO('contacts')}>
                            <Text size="small">{t('CONTACTS')}</Text>
                        </Link>
                    </Grid.Item>
                    <Grid.Item xl={2} sm={2} xs={4} className={styles.gridItem}>
                        <Link to={urls.INFO('agreement')}>
                            <Text size="small">{t('AGREEMENT')}</Text>
                        </Link>
                    </Grid.Item>
                    <Grid.Item xl={2} sm={2} xs={4} className={styles.gridItem}>
                        <Link to={urls.INFO('privacy-policy')}>
                            <Text size="small">{t('PRIVACY_POLICY')}</Text>
                        </Link>
                    </Grid.Item>
                    <Grid.Item xl={2} sm={2} xs={4} className={styles.gridItem}>
                        <Link to={urls.INFO('courses')}>
                            <Text size="small">{t('COURSES')}</Text>
                        </Link>
                    </Grid.Item>
                </Grid.Row>
                <div className={styles.copyright}>
                    <Copyright />
                </div>
            </Grid.Container>
        </footer>
    );
};

export default Footer;
