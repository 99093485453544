// @flow strict
import React, { useCallback, type Node, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotificationActions, NOTIFICATION_TYPE } from '@xyz-school/xyz-react-frontend';

import { type SocialAuthProviderType, api, type SocialConnectionsType } from '@/model/User';
import { getServerError } from '@/core/form';

import SocialAuthUi from './SocialAuthUi';

type GetOnDeleteType = (
    apiMethod: typeof api.deleteSocialAuth,
    setButtonsData: ((SocialConnectionsType) => SocialConnectionsType) => void,
) => (provider: SocialAuthProviderType) => Promise<void>;

export const getOnDelete: GetOnDeleteType = (apiMethod, setButtonsData) => async (provider) => {
    await apiMethod(provider);
    setButtonsData((oldData) =>
        oldData.map((item) => (item.provider === provider ? { ...item, isConnected: false } : item)),
    );
};

export type Props = {|
    className?: string,
    getLink: (provider: SocialAuthProviderType) => string,
|};

const SocialAuth = ({ className, getLink }: Props): Node => {
    const [buttonsData, setButtonsData] = useState<SocialConnectionsType>([]);
    const { pushNotification } = useNotificationActions();
    const { t } = useTranslation(['socialAuth']);

    useEffect(() => {
        api.socialAuth().then(({ connections }) => setButtonsData(connections));
    }, []);

    const onAuth = useCallback(
        (provider: SocialAuthProviderType) => {
            window.location.assign(getLink(provider));
        },
        [getLink],
    );

    const onDelete = async (provider: SocialAuthProviderType): Promise<void> => {
        try {
            await getOnDelete(api.deleteSocialAuth, setButtonsData)(provider);
            pushNotification({
                type: NOTIFICATION_TYPE.success,
                message: t('DELETE_SUCCESS'),
            });
        } catch (e) {
            pushNotification({
                type: NOTIFICATION_TYPE.error,
                message: getServerError(e) || t('DELETE_ERROR'),
            });
        }
    };

    return <SocialAuthUi className={className} buttonsData={buttonsData} onAuth={onAuth} onDelete={onDelete} />;
};

export default SocialAuth;
