// @flow
import React, { type Node } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@xyz-school/xyz-react-frontend';
import { type ProfileItemsType, useProfileNav } from '@/model/Profile';

import styles from './ProfileMenu.scss';

export type Props = {|
    /** Current item slug */
    currentItem: ProfileItemsType,
    /** Event when `currentItem` is changed */
    onChange: (slug: ProfileItemsType) => void,
    isShowActive?: boolean,
|};

function ProfileMenu({ currentItem, onChange, isShowActive }: Props): Node {
    const { t } = useTranslation('profile');
    const navItems = useProfileNav();

    const getIsActive = (item: ProfileItemsType) => isShowActive && currentItem && currentItem === item;

    return (
        <div className={styles.menu}>
            {navItems.map((item) => (
                <div key={item} onClick={() => onChange(item)} className={styles.item}>
                    <div className={styles.menuItemsWrap}>
                        <Text
                            className={classnames({
                                [styles.active]: getIsActive(item),
                            })}
                        >
                            {t(item)}
                        </Text>
                        <div className={styles.menuIcon}>
                            <Icon name="caret-right" color="text" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ProfileMenu;
