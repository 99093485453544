// @flow typed
import { useState } from 'react';
import { type FormikHelpers } from 'formik';
import { useNotificationActions, NOTIFICATION_TYPE } from '@xyz-school/xyz-react-frontend';
import { useTranslation } from 'react-i18next';

import { getServerError, type ServerErrorsType, getNoFieldNotify } from '@/core/form';

export type SubmitType<FormType> = (
    values: FormType,
    onError: (serverErrors: ServerErrorsType<FormType>) => void,
    resetForm: $PropertyType<FormikHelpers<FormType>, 'resetForm'>,
) => Promise<void>;

type OptionsType<FormType> = {
    +setErrors: $PropertyType<FormikHelpers<FormType>, 'setErrors'>,
    +setSubmitting: $PropertyType<FormikHelpers<FormType>, 'setSubmitting'>,
    +resetForm: $PropertyType<FormikHelpers<FormType>, 'resetForm'>,
};

export type OnSubmitType<FormType> = (submitValues: FormType, options: OptionsType<FormType>) => Promise<void>;

const useOnSubmit = <FormType: {}>(submit: SubmitType<FormType>): OnSubmitType<FormType> => {
    const { t } = useTranslation(['errors']);
    const { pushNotification, removeNotification } = useNotificationActions();
    const [currentNotificationId, setCurrentNotificationId] = useState<?string>();

    const resetNotification = (): void => {
        if (currentNotificationId) removeNotification(currentNotificationId);
    };

    const getOnServerError = (setErrors) => (serverErrors) => {
        const { errors: restErrors, notificationId } = getNoFieldNotify(pushNotification, serverErrors);
        if (notificationId) setCurrentNotificationId(notificationId);
        const keys = Object.keys(restErrors);
        const errorsArray = keys.map((key) => [key, getServerError(restErrors[key])]);
        setErrors(Object.fromEntries(errorsArray));
    };

    return async (submitValues, { setErrors, setSubmitting, resetForm }) => {
        resetNotification();
        try {
            await submit(submitValues, getOnServerError(setErrors), resetForm);
        } catch (error) {
            pushNotification({ type: NOTIFICATION_TYPE.error, message: t('UNKNOWN') });
        } finally {
            setSubmitting(false);
        }
    };
};

export default useOnSubmit;
