// @flow strict
import { type FormikErrors } from 'formik';

const validateRequired = <T: {}>(values: T, name: string, error: string | boolean): FormikErrors<T> => {
    if (!values[name] || values[name] === '') {
        return { [name]: error };
    }
    return {};
};

export default validateRequired;
