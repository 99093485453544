// @flow strict
import React, { type Node } from 'react';

import { Link, Text } from '@xyz-school/xyz-react-frontend';

import { useCurrentUser } from '@/model/User';

type Props = {|
    text: string,
    url: string,
    isUnauthorizedAllowed?: boolean,
|};

const MessageBoxLink = ({ text, url, isUnauthorizedAllowed = false }: Props): Node => {
    const { userData } = useCurrentUser();

    if (!isUnauthorizedAllowed && !userData.isLogin) {
        return null;
    }

    return (
        <Link to={url}>
            <Text color="accent" align="center">
                {text}
            </Text>
        </Link>
    );
};

export default MessageBoxLink;
