// @flow strict
import React, { type Node, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Input, Link, useLocalizedHistory } from '@xyz-school/xyz-react-frontend';

import urls from '@/constants/urls';
import commonStyles from '@/core/styles.scss';
import { type ServerErrorsType, useForm } from '@/core/form';
import { api, useSignupEmail } from '@/model/User';

import styles from './PageSignupForm.scss';

type FormType = {|
    username?: string,
    email?: string,
    password1?: string,
    password2?: string,
    agreement?: boolean,
|};

const initialValues: FormType = {
    username: '',
    email: '',
    password1: '',
    password2: '',
    agreement: false,
};

const validatePasswordsEqual = (values: FormType, name: string, error: string | boolean) => {
    if (values.password1 && values.password2 && values.password1 !== values.password2) {
        return {
            [name]: error,
        };
    }
    return {};
};

type Props = {|
    className?: string,
|};

const PageSignupForm = ({ className }: Props): Node => {
    const { t } = useTranslation(['signup']);
    const history = useLocalizedHistory();
    const { setEmail } = useSignupEmail();

    const { handleSubmit, handleChange, values, errors, isDisabled, setFieldError } = useForm<FormType>(
        initialValues,
        async ({ username, email, password1, password2 }, onError) => {
            if (username && email && password1 && password2) {
                try {
                    await api.signup({
                        username,
                        email,
                        password1,
                        password2,
                    });
                    setEmail(email);
                    history.push(urls.CONFIRM_EMAIL);
                } catch (error) {
                    const serverErrors: ServerErrorsType<FormType> = error;
                    onError(serverErrors);
                }
            }
        },
        ['username', 'email', 'password1', 'password2', 'agreement'],
        (formValues) => validatePasswordsEqual(formValues, 'password1', t('ERROR_PASSWORD_EQUAL')),
    );

    // reset password1 error when password2 changed
    useEffect(() => {
        setFieldError('password1');
    }, [setFieldError, values.password2]);

    return (
        <form className={className} onSubmit={handleSubmit}>
            <Input
                type="text"
                name="username"
                onChange={handleChange}
                value={values.username}
                errorMessage={errors.username}
                className={styles.input}
                label={t('NICKNAME')}
                autoComplete="nickname"
            />
            <Input
                name="email"
                type="text"
                onChange={handleChange}
                value={values.email}
                errorMessage={errors.email}
                className={styles.input}
                label="Email"
                autoComplete="email"
            />
            <Input
                name="password1"
                type="password"
                onChange={handleChange}
                value={values.password1}
                errorMessage={errors.password1}
                className={styles.input}
                label={t('PASSWORD_FIRST')}
                autoComplete="new-password"
            />
            <Input
                name="password2"
                type="password"
                onChange={handleChange}
                value={values.password2}
                errorMessage={errors.password2}
                label={t('PASSWORD_SECOND')}
                autoComplete="new-password"
            />
            <div className={styles.agreement}>
                <Checkbox
                    className={styles.checkbox}
                    name="agreement"
                    onChange={handleChange}
                    isChecked={values.agreement}
                    errorMessage={errors.agreement}
                >
                    {t('AGREE')}
                </Checkbox>
                <Link to={urls.INFO('agreement')} className={commonStyles.link}>
                    {t('AGREEMENT')}
                </Link>
            </div>
            <Button isFullWidth type="submit" size="large" styleType="primary" isDisabled={isDisabled}>
                {t('SUBMIT')}
            </Button>
        </form>
    );
};

export default PageSignupForm;
