// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@xyz-school/xyz-react-frontend';

import { useRepeatConfirm } from '@/model/User';
import MessageBox from '@/features/MessageBox';

export type Props = {|
    email: string,
    onReset: (email: string) => Promise<{}>,
|};

const EmailSent = ({ email, onReset }: Props): Node => {
    const { t } = useTranslation(['resetPassword']);

    const sendAgain = useRepeatConfirm(onReset, t('SENT_AGAIN_NOTIFY'));
    const onSendAgain = (): Promise<void> => sendAgain(email);

    return (
        <MessageBox.Layout>
            <MessageBox
                icon={<Icon name="mail" color="text" size="largest" />}
                title={t('EMAIL_SENT_TITILE')}
                description={t('EMAIL_SENT_TEXT', { email })}
                button={<MessageBox.Button text={t('SEND_AGAIN')} onClick={onSendAgain} />}
            />
        </MessageBox.Layout>
    );
};

export default EmailSent;
