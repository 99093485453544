// @flow strict
import React, { type Node, type Element } from 'react';
import classNames from 'classnames';

import { Container, Heading, Icon, Text } from '@xyz-school/xyz-react-frontend';

import MessageBoxLayout from './MessageBoxLayout';
import MessageBoxButton from './MessageBoxButton';
import MessageBoxLink from './MessageBoxLink';

import styles from './MessageBox.scss';

type Props = {|
    title: string,
    description?: string,
    icon?: Element<typeof Icon>,
    button?: ?Element<typeof MessageBoxButton>,
    link?: Element<typeof MessageBoxLink>,
    className?: string,
|};

const MessageBox = ({ title, description, icon, button, link, className }: Props): Node => {
    return (
        <div className={classNames(styles.MessageBox, className)}>
            {icon && (
                <Container marginBottom={5} marginBottomXs={4}>
                    {icon}
                </Container>
            )}

            <Container textAlign="center">
                <Heading level={1}>{title}</Heading>
            </Container>

            {description && (
                <Container marginTop={1} marginTopXs={0.5}>
                    <Text align="center">{description}</Text>
                </Container>
            )}

            {button && (
                <Container marginTop={5} marginTopXs={4} className={styles.MessageBox__button}>
                    {button}
                </Container>
            )}

            {link && <Container marginTop={4}>{link}</Container>}
        </div>
    );
};

MessageBox.Layout = MessageBoxLayout;
MessageBox.Button = MessageBoxButton;
MessageBox.Link = MessageBoxLink;

export default MessageBox;
