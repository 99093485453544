// @flow strict
import React, { type Node } from 'react';
import {
    Container,
    Input,
    useLocalizedHistory,
    useNotificationActions,
    NOTIFICATION_TYPE,
} from '@xyz-school/xyz-react-frontend';

import { useTranslation } from 'react-i18next';
import { type ServerErrorsType, useForm } from '@/core/form';
import { api, useEmail, type SetEmailDTO } from '@/model/User';
import { PROFILE_ITEMS } from '@/model/Profile';
import ActionButtons from '@/features/ActionButtons';
import urls from '@/constants/urls';

const initialValues: SetEmailDTO = {
    email: '',
    password1: '',
    password2: '',
};

const Form = (): Node => {
    const { t } = useTranslation(['setEmail', 'profile']);
    const history = useLocalizedHistory();
    const { setNewEmail } = useEmail();
    const { pushNotification } = useNotificationActions();

    const { handleSubmit, handleChange, values, errors, isDisabled } = useForm<SetEmailDTO>(
        initialValues,
        async (submitValues: SetEmailDTO, onError) => {
            try {
                await api.setEmail({ ...submitValues });
                setNewEmail(submitValues.email);
                pushNotification({ type: NOTIFICATION_TYPE.success, message: t('profile:SAVED') });
                history.push(`${urls.PROFILE}${PROFILE_ITEMS.authorization.toLowerCase()}`);
            } catch (error) {
                const serverErrors: ServerErrorsType<SetEmailDTO> = error;
                onError(serverErrors);
            }
        },
        ['email', 'password1', 'password2'],
    );

    const onCancel = () => {
        history.goBack();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Container marginBottom={1.5}>
                <Input
                    onChange={handleChange}
                    value={values.email}
                    type="email"
                    name="email"
                    label={t('EMAIL_PLACEHOLDER')}
                    errorMessage={errors.email}
                    autoComplete="new-password"
                />
            </Container>
            <Container marginBottom={1.5}>
                <Input
                    onChange={handleChange}
                    value={values.password1}
                    type="password"
                    name="password1"
                    label={t('PASSWORD1_PLACEHOLDER')}
                    errorMessage={errors.password1}
                    autoComplete="new-password"
                />
            </Container>
            <Container marginBottom={3}>
                <Input
                    onChange={handleChange}
                    value={values.password2}
                    type="password"
                    name="password2"
                    label={t('PASSWORD2_PLACEHOLDER')}
                    errorMessage={errors.password2}
                    autoComplete="new-password"
                />
            </Container>

            <ActionButtons onCancel={onCancel} isDisabled={isDisabled} />
        </form>
    );
};

export default Form;
