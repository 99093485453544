// @flow
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Button, useMedia } from '@xyz-school/xyz-react-frontend';

type Props = {|
    isSubmitDisabled?: boolean,
    className?: string,
|};

const SubmitControls = ({ isSubmitDisabled = false, className }: Props): Node => {
    const { t } = useTranslation('profile');
    const media = useMedia();

    return (
        <div className={classnames(className)}>
            <Button
                isDisabled={isSubmitDisabled}
                size="large"
                type="submit"
                styleType="primary"
                isFullWidth={!(media.lg || media.xl)}
            >
                {t('SAVE')}
            </Button>
        </div>
    );
};

export default SubmitControls;
