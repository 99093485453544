// @flow
import i18next from 'i18next';
import { getCookie } from '@xyz-school/xyz-react-frontend/lib/utils/cookie';
import { type queryParams, queryStringify } from '@xyz-school/xyz-react-frontend';

import { fileApi } from './fileApi';

export type MethodType = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

type HeadersType = { [name: string]: string };

export const getCommonHeaders = (): HeadersType => ({
    'X-CSRFToken': getCookie({ name: 'csrftoken' }) || '',
    'X-Language': i18next.language,
});

export const parseData = <T: ?{}>(data?: T): { body: string, headers: HeadersType } => ({
    headers: {
        ...getCommonHeaders(),
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data) || '',
});

const baseUrl = 'api';

type UrlDataType = {
    url: string,
    query: string,
};

export const coreApi = async <R: {}>({ url, query }: UrlDataType, reqOptions: RequestOptions): Promise<R> => {
    const response = await fetch(`/${baseUrl}/${url}/${query}`, reqOptions);

    const json: $Shape<R> = await response.json().catch(() => ({}));
    if (response.ok) {
        return json;
    }
    return Promise.reject(json);
};

type DataType<T: ?{}> = {|
    query?: queryParams,
    body?: T,
|};

export const commonApi = async <R: {}, T: ?{}>(method: MethodType, url: string, data?: DataType<T>): Promise<R> => {
    const { headers, body } = parseData(data && data.body);

    const reqOptions = {
        method,
        headers,
        body: method === 'GET' ? undefined : body,
    };

    return coreApi(
        {
            url,
            query: data && data.query ? `?${queryStringify(data.query)}` : '',
        },
        reqOptions,
    );
};

export const api = async <R: {}, T: ?{}>(method: MethodType, url: string, data?: DataType<T>): Promise<R> => {
    if (data && data.body) {
        const { body } = data;
        const isFormData = !!Object.values(body).find((item) => item instanceof Blob);
        if (isFormData) {
            return fileApi(method, url, body);
        }
    }
    return commonApi(method, url, data);
};
