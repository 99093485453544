// @flow strict
import { PROFILE_ITEMS, type ProfileItemsType } from '@/model/Profile';

const useProfileNav = (): Array<ProfileItemsType> => {
    const profileNavAccess = {
        [PROFILE_ITEMS.general]: true,
        [PROFILE_ITEMS.authorization]: true,
    };

    const initialNavItems: Array<ProfileItemsType> = Object.keys(profileNavAccess);

    return initialNavItems.filter((item) => profileNavAccess[item]);
};

export default useProfileNav;
