// @flow strict
import React, { type Node, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageMetaTags } from '@xyz-school/xyz-react-frontend';

import MainLayout, { LayoutHeading } from '@/features/MainLayout';
import CenteredLayout from '@/features/CenteredLayout';
import { api } from '@/model/User';

import EmailSent from './EmailSent';
import Form from './Form';

const PageResetPassword = (): Node => {
    const { t } = useTranslation(['resetPassword']);
    const [email, setEmail] = useState<?string>();

    return (
        <MainLayout>
            <PageMetaTags title={t('TITLE')} />
            {email ? (
                <EmailSent onReset={api.resetPassword} email={email} />
            ) : (
                <>
                    <LayoutHeading>{t('TITLE')}</LayoutHeading>
                    <CenteredLayout>
                        <Form setEmail={setEmail} onReset={api.resetPassword} />
                    </CenteredLayout>
                </>
            )}
        </MainLayout>
    );
};

export default PageResetPassword;
