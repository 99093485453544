// @flow
import React, { type Node } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { useLanguage } from '@xyz-school/xyz-react-frontend';

import { useCurrentUser, useEmail, useFeatures } from '@/model/User';
import urls from '@/constants/urls';

import PageHome from '@/pages/PageHome';
import PageLogin from '@/pages/PageLogin';
import PageSignup from '@/pages/PageSignup';
import PageProfile from '@/pages/PageProfile';
import PageChangeEmail from '@/pages/PageChangeEmail';
import PageConfirmEmail from '@/pages/PageConfirmEmail';
import PageSetEmail from '@/pages/PageSetEmail';
import PageSignupSuccess from '@/pages/PageSignupSuccess';
import PageConfirmed from '@/pages/PageConfirmed';
import PageResetPassword from '@/pages/PageResetPassword';
import PageNewPassword from '@/pages/PageNewPassword';
import PageInfo from '@/pages/PageInfo';

const Routes = (): Node => {
    const features = useFeatures();
    const { path } = useRouteMatch();
    const { toLocalizedRoute } = useLanguage();
    const { email, unconfirmedEmail } = useEmail();
    const {
        userData: { isLogin },
    } = useCurrentUser();

    return (
        <Switch>
            <Route exact path={`${path}${urls.PAGE_HOME}`} component={PageHome} />

            {!isLogin && <Route exact path={`${path}${urls.LOGIN()}`} component={PageLogin} />}

            {!isLogin && <Route exact path={`${path}${urls.SIGNUP()}`} component={PageSignup} />}

            {!isLogin && <Route exact path={`${path}${urls.RESET}:key`} component={PageNewPassword} />}

            {!isLogin && <Route exact path={`${path}${urls.RESET}`} component={PageResetPassword} />}

            {isLogin && <Route path={`${path}${urls.PROFILE}:slug`} component={PageProfile} />}

            {isLogin && features.socialNetworkRegistration && !email && !unconfirmedEmail && (
                <Route path={`${path}${urls.SET_EMAIL}`} component={PageSetEmail} />
            )}

            {isLogin && (!!email || !!unconfirmedEmail) && (
                <Route path={`${path}${urls.CHANGE_EMAIL}`} component={PageChangeEmail} />
            )}

            {isLogin && features.socialNetworkRegistration && (
                <Route path={`${path}${urls.SIGNUP_SUCCESS}`} component={PageSignupSuccess} />
            )}

            <Route path={`${path}${urls.CONFIRM_EMAIL}:key`} component={PageConfirmed} />

            {!!unconfirmedEmail && <Route path={`${path}${urls.CONFIRM_EMAIL}`} component={PageConfirmEmail} />}

            <Route path={`${path}${urls.INFO(':slug')}`}>
                {({ match }) => match && <PageInfo slug={(match.params.slug: any)} />}
            </Route>

            <Route path="*">
                {!isLogin && <Redirect to={toLocalizedRoute(urls.LOGIN())} />}
                {isLogin && <Redirect to={toLocalizedRoute(`${urls.PROFILE}general`)} />}
            </Route>
        </Switch>
    );
};

export default Routes;
