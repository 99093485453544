// @flow strict
import React, { type Node } from 'react';
import {
    Input,
    Checkbox,
    useLocalizedHistory,
    useNotificationActions,
    NOTIFICATION_TYPE,
} from '@xyz-school/xyz-react-frontend';

import { useTranslation } from 'react-i18next';

import ActionButtons from '@/features/ActionButtons';
import { api, useCurrentUser } from '@/model/User';
import { useForm, type ServerErrorsType } from '@/core/form';

import styles from './Form.scss';

type FormType = {|
    password1: string,
    password2: string,
    remember: boolean,
|};

const initialValues: FormType = {
    password1: '',
    password2: '',
    remember: false,
};

type ServerFormType = {|
    ...FormType,
    key: string,
|};

type Props = {|
    keyToken: string,
|};

const Form = ({ keyToken: key }: Props): Node => {
    const { t } = useTranslation(['newPassword']);
    const history = useLocalizedHistory();
    const { pushNotification } = useNotificationActions();
    const { updateUser } = useCurrentUser();

    const { handleSubmit, handleChange, values, errors, isDisabled } = useForm<FormType>(
        initialValues,
        async (submitValues, onError) => {
            try {
                const newUser = await api.changePassword({ ...submitValues, key });
                updateUser(newUser);
                pushNotification({ type: NOTIFICATION_TYPE.success, message: t('SUCCESS') });
            } catch (error) {
                const { key: keyError, ...serverErrors }: ServerErrorsType<ServerFormType> = error;
                if (keyError) {
                    pushNotification({ type: NOTIFICATION_TYPE.error, message: t('WRONG_LINK') });
                }
                onError(serverErrors);
            }
        },
        ['password1', 'password2'],
    );

    const onCancel = () => {
        history.push('/');
    };

    return (
        <form onSubmit={handleSubmit}>
            <Input
                onChange={handleChange}
                value={values.password1}
                type="password"
                name="password1"
                label={t('PASSWORD1')}
                className={styles.minorMargin}
                errorMessage={errors.password1}
                autoComplete="new-password"
            />
            <Input
                onChange={handleChange}
                value={values.password2}
                type="password"
                name="password2"
                label={t('PASSWORD2')}
                className={styles.magorMargin}
                errorMessage={errors.password2}
                autoComplete="new-password"
            />
            <Checkbox
                isChecked={values.remember}
                onChange={handleChange}
                name="remember"
                className={styles.magorMargin}
            >
                {t('REMEMBER')}
            </Checkbox>
            <ActionButtons onCancel={onCancel} isDisabled={isDisabled} />
        </form>
    );
};

export default Form;
