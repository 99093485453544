// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Checkbox, Link, Button } from '@xyz-school/xyz-react-frontend';

import urls from '@/constants/urls';
import { type ServerErrorsType, useForm } from '@/core/form';
import commonStyles from '@/core/styles.scss';
import { api, useCurrentUser } from '@/model/User';

import styles from './PageLoginForm.scss';

type FormType = {| email: string, password: string, remember: boolean |};

const initialValues: FormType = {
    email: '',
    password: '',
    remember: true,
};

type Props = {|
    next: ?string,
|};

const PageLoginForm = ({ next }: Props): Node => {
    const { t } = useTranslation(['login']);
    const { updateUser } = useCurrentUser();

    const { handleSubmit, handleChange, values, errors, isDisabled } = useForm<FormType>(
        initialValues,
        async (formValues, onError) => {
            try {
                const newUser = await api.login(formValues);
                if (next) {
                    window.location.href = next;
                } else {
                    updateUser(newUser);
                }
            } catch (error) {
                const serverErrors: ServerErrorsType<FormType> = error;
                onError(serverErrors);
            }
        },
        ['email', 'password'],
    );

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Input
                onChange={handleChange}
                value={values.email}
                errorMessage={errors.email}
                type="text"
                name="email"
                label="Email"
                className={styles.email}
                autoComplete="email"
            />
            <Input
                name="password"
                onChange={handleChange}
                value={values.password}
                errorMessage={errors.password}
                type="password"
                label={t('PASSWORD_PLACEHOLDER')}
                autoComplete="current-password"
            />
            <div className={styles.options}>
                <Checkbox name="remember" onChange={handleChange} isChecked={values.remember}>
                    {t('REMEMBER')}
                </Checkbox>
                <Link to={urls.RESET} className={commonStyles.link}>
                    {t('RESET')}
                </Link>
            </div>
            <Button isFullWidth type="submit" size="large" styleType="primary" isDisabled={isDisabled}>
                {t('SUBMIT')}
            </Button>
        </form>
    );
};

export default PageLoginForm;
