// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';
import { Container, Grid } from '@xyz-school/xyz-react-frontend';

import mainLayoutStyles from '@/features/MainLayout/MainLayout.scss';
import { type ProfileItemsType } from '@/model/Profile';

import ContentWrapper from '../../ContentWrapper';
import FormGeneral from '../../FormGeneral';
import FormAuthorization from '../../FormAuthorization';
import FormPassword from '../../FormPassword';
import Photo from '../../Photo';
import ModalHeader from '../ModalHeader';

import styles from './Content.scss';

const forms = {
    general: (
        <>
            <Grid.Row>
                <Grid.Item sm={1} />
                <Grid.Item sm={2}>
                    <Container marginBottomSm={3}>
                        <Photo />
                    </Container>
                </Grid.Item>
                <Grid.Item sm={1} />
            </Grid.Row>
            <Grid.Row>
                <Grid.Item>
                    <FormGeneral />
                </Grid.Item>
            </Grid.Row>
        </>
    ),
    authorization: <FormAuthorization />,
    password: <FormPassword />,
};

export type Props = {
    onCancel: () => void,
    currentSection: ProfileItemsType,
};

const Content = ({ onCancel, currentSection }: Props): Node => (
    <div className={styles.wrapper}>
        <ModalHeader onCancel={onCancel} currentSection={currentSection} />
        <Grid.Container className={styles.container}>
            <div className={classnames(mainLayoutStyles.wrapper, styles.layout)}>
                <ContentWrapper currentContent={currentSection} forms={forms} />
            </div>
        </Grid.Container>
    </div>
);

export default Content;
