// @flow strict
import * as authApi from './authApi';
import * as profileApi from './profileApi';
import * as emailApi from './emailApi';
import * as passwordApi from './passwordApi';
import * as settingsApi from './settingsApi';

export type { LoginDataType } from './authApi';
export type { SignupDataType } from './authApi';

export type { ChangeEmailRepeatType } from './emailApi';
export type { ConfirmEmailType } from './emailApi';
export type { SetEmailDTO } from './emailApi';

export const api = {
    ...authApi,
    ...profileApi,
    ...emailApi,
    ...passwordApi,
    ...settingsApi,
};
