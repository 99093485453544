// @flow strict
import { useCallback } from 'react';

import { useCurrentUser, useSignupEmail } from '@/model/User';

type SetNewEmailType = (newEmail?: string) => void;

type ReturnType = {
    email: ?string,
    setNewEmail: SetNewEmailType,
    unconfirmedEmail: ?string,
};

const useEmail = (): ReturnType => {
    const { userData, updateUser } = useCurrentUser();
    const { isLogin } = userData;

    const { email: signupEmail, setEmail: setSignupEmail } = useSignupEmail();

    const contextEmail = userData.isLogin ? userData.user.email : '';
    const contextUnconfirmedEmail = userData.isLogin ? userData.user.unconfirmedEmail : '';

    const setNewEmail = useCallback<SetNewEmailType>(
        (newEmail) => {
            if (isLogin) {
                updateUser((prev) => prev && { ...prev, unconfirmedEmail: newEmail });
            } else {
                setSignupEmail(newEmail);
            }
        },
        [isLogin, updateUser, setSignupEmail],
    );

    return {
        email: contextEmail || signupEmail,
        unconfirmedEmail: contextUnconfirmedEmail || signupEmail,
        setNewEmail,
    };
};

export default useEmail;
