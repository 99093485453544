// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@xyz-school/xyz-react-frontend';

import urls from '@/constants/urls';
import { api } from '@/model/User';
import MessageBox from '@/features/MessageBox';

import useConfirm from './useConfirm';

const REDIRECT_DELAY = 5; // Задержка перед автоматическим редиректом в секундах

const PageConfirmed = (): Node => {
    const { t } = useTranslation(['confirmed']);
    const { app } = useConfirm(api.confirmEmail);

    return (
        <MessageBox.Layout title={t('TITLE')}>
            <MessageBox
                icon={<Icon name="round-check" color="success" size="largest" />}
                title={t('TITLE')}
                description={t('TEXT')}
                button={
                    <MessageBox.Button
                        text={app?.title}
                        onClick={app?.redirectUrl}
                        redirectDelay={REDIRECT_DELAY}
                        isService={!!app}
                    />
                }
                link={<MessageBox.Link text={t('PROFILE_LINK')} url={urls.PROFILE} />}
            />
        </MessageBox.Layout>
    );
};

export default PageConfirmed;
