// @flow
import React, { useState, type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Grid, Heading } from '@xyz-school/xyz-react-frontend';
import MainLayout from '@/features/MainLayout';
import { type ProfileItemsType } from '@/model/Profile';

import ProfileMenu from '../ProfileMenu';
import Content from './Content';
import styles from './MobileView.scss';

export type Props = {
    currentSection: ProfileItemsType,
    onSetNewMenuItem: (newItem: ProfileItemsType) => void,
};

const MobileView = ({ currentSection, onSetNewMenuItem }: Props): Node => {
    const { t } = useTranslation(['profile']);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);

    const onChangeMenuItem = (newItem: ProfileItemsType) => {
        onSetNewMenuItem(newItem);
        setIsMenuOpen(false);
    };

    const onCancel = () => setIsMenuOpen(true);

    return (
        <MainLayout>
            <Heading level={1} className={styles.heading}>
                {t('TITLE')}
            </Heading>
            {isMenuOpen ? (
                <Grid.Row>
                    <Grid.Item>
                        <ProfileMenu currentItem={currentSection} onChange={onChangeMenuItem} isShowActive={false} />
                    </Grid.Item>
                </Grid.Row>
            ) : (
                <Modal isOpened isPadded={false} onClose={onCancel} closeOnClickOutside={false}>
                    <Content onCancel={onCancel} currentSection={currentSection} />
                </Modal>
            )}
        </MainLayout>
    );
};

export default MobileView;
