// @flow strict
import React, { type Node } from 'react';

import { Container, GridContainer, GridCol, GridRow } from '@xyz-school/xyz-react-frontend';

import styles from './WelcomeScreenLayout.scss';

type Props = {|
    midBlockContent?: Node,
    botBlockContent?: Node,
|};

const WelcomeScreenLayout = ({ midBlockContent, botBlockContent }: Props): Node => {
    return (
        <GridContainer className={styles.layout}>
            <div className={styles.col}>
                <div />
                <Container marginTop={6} marginTopXs={12} marginBottom={6} marginBottomSm={10} marginBottomXs={7}>
                    <GridRow>
                        <GridCol xl={4} sm={1} xs={4} />
                        <GridCol xl={8} sm={3} xs={4}>
                            {midBlockContent}
                        </GridCol>
                    </GridRow>
                </Container>
                <Container marginBottom={6} marginBottomSm={0}>
                    {botBlockContent}
                </Container>
            </div>
        </GridContainer>
    );
};

export default WelcomeScreenLayout;
