// @flow strict

export const SOCIAL_AUTH_PROVIDER = Object.freeze({
    vk: 'vk',
    discord: 'discord',
    facebook: 'facebook',
    twitter: 'twitter',
});

export type SocialAuthProviderType = $Values<typeof SOCIAL_AUTH_PROVIDER>;

export type SocialConnectionsType = Array<{
    provider: SocialAuthProviderType,
    isConnected: boolean,
}>;
