// @flow strict
import { type Node } from 'react';

import { type ProfileItemsType, PROFILE_ITEMS } from '@/model/Profile';

type Props = {
    currentContent: ProfileItemsType,
    forms: {
        general: Node,
        authorization: Node,
    },
};

const ContentWrapper = ({ currentContent, forms: { general, authorization } }: Props): Node => {
    switch (currentContent) {
        case PROFILE_ITEMS.general:
            return general;
        case PROFILE_ITEMS.authorization:
            return authorization;
        default:
            return null;
    }
};

export default ContentWrapper;
