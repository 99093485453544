// @flow strict
import { useLocation } from 'react-router-dom';

import { isHttpUrl } from '@/core/utils/url';
import type { AppRedirectData } from '@/core/types';

type RegistrationType = 'email' | 'social';

type ReturnType = {|
    redirectData: AppRedirectData | null,
    isHttpRedirect: boolean,
    isRegistrationType: (type: RegistrationType) => boolean,
|};

const useRedirectData = (): ReturnType => {
    const { search } = useLocation();

    const registrationType = new URLSearchParams(search).get('type');
    const redirectName = new URLSearchParams(search).get('redirectName');
    const redirectUrl = new URLSearchParams(search).get('redirectUrl');

    const isHttpRedirect = !!redirectUrl && isHttpUrl(redirectUrl);

    const redirectData = !!redirectName && !!redirectUrl ? { title: redirectName, redirectUrl } : null;

    const isRegistrationType = (type: RegistrationType): boolean => {
        return !!registrationType && registrationType === type;
    };

    return {
        redirectData,
        isHttpRedirect,
        isRegistrationType,
    };
};

export default useRedirectData;
