// @flow strict
import React, { type Node } from 'react';

import { PageMetaTags } from '@xyz-school/xyz-react-frontend';

import MainLayout from '@/features/MainLayout';
import CenteredLayout from '@/features/CenteredLayout';

type Props = {|
    title?: string,
    children: Node,
|};

const MessageBoxLayout = ({ title, children }: Props): Node => {
    return (
        <>
            {title && <PageMetaTags title={title} />}
            <MainLayout>
                <CenteredLayout>{children}</CenteredLayout>
            </MainLayout>
        </>
    );
};

export default MessageBoxLayout;
