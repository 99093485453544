// @flow strict
import { api as appApi } from '@/core/api';

import { type UserType, type SocialConnectionsType, type SocialAuthProviderType } from '..';

export type LoginDataType = {| email: string, password: string, remember: boolean |};
export type SignupDataType = {| username: string, email: string, password1: string, password2: string |};

export const login = (data: LoginDataType): Promise<UserType> =>
    appApi<UserType, LoginDataType>('POST', 'users/login', { body: data });

export const logout = (): Promise<{}> => appApi('POST', 'users/logout');

export const signup = (data: SignupDataType): Promise<{}> =>
    appApi<{}, SignupDataType>('POST', 'users/signup', { body: data });

type SocialAuthType = { connections: SocialConnectionsType };

export const socialAuth = (): Promise<SocialAuthType> => appApi<SocialAuthType, {}>('GET', 'users/social-auth');

export const deleteSocialAuth = (provider: SocialAuthProviderType): Promise<{}> =>
    appApi<{}, {}>('DELETE', `users/social-auth/${provider}`);
