// @flow strict
import React, { type AbstractComponent } from 'react';

import { useCurrentUser } from '../CurrentUserProvider';
import { type UserType } from '../User';

const withUser = <Config>(Component: AbstractComponent<{| ...Config, user: UserType |}>): AbstractComponent<Config> => {
    return (props: Config) => {
        const { userData } = useCurrentUser();
        if (userData.isLogin) return <Component {...props} user={userData.user} />;
        return null;
    };
};

export default withUser;
