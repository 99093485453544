// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';
import { Text } from '@xyz-school/xyz-react-frontend';

import commonStyles from '@/core/styles.scss';

import { type TextSizeType } from '..';

import styles from './ActionButton.scss';

export type Props = {|
    children: string,
    onClick: () => void | Promise<void>,
    size?: TextSizeType,
|};

const ActionButton = ({ children, onClick, size = 'small' }: Props): Node => (
    <button type="button" onClick={onClick} className={classnames(commonStyles.link, styles.button)}>
        <Text size={size} tag="span" color="inherit">
            {children}
        </Text>
    </button>
);

export default ActionButton;
