// @flow strict
import React, { type Node, useEffect, useState, useContext, type Context } from 'react';

import { useLanguage } from '@xyz-school/xyz-react-frontend';

import { api } from './api';
import useUserState, { type UseUserStateType } from './useUserState';
import { EMPTY_USER_SETTINGS, type UserSettingsType } from './UserSettings';
import useLoadSettings from './useLoadSettings';

type CurrentUserContextType = {|
    userData: $PropertyType<UseUserStateType, 'userData'>,
    settings: UserSettingsType,
    updateUser: $PropertyType<UseUserStateType, 'setUser'>,
|};

export const CurrentUser: Context<CurrentUserContextType> = React.createContext<CurrentUserContextType>({
    userData: { isLogin: false },
    settings: EMPTY_USER_SETTINGS,
    updateUser: () => {},
});

type Props = {| children: Node |};

export const CurrentUserProvider = ({ children }: Props): Node => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { userData, setUser } = useUserState();
    const { currentLang } = useLanguage();
    const [{ settings, isLoading: isSettingsLoading }, { loadSettings }] = useLoadSettings();

    useEffect(() => {
        loadSettings();
    }, [currentLang, loadSettings]);

    useEffect(() => {
        api.me()
            .then(setUser)
            .catch(() => setUser())
            .finally(() => setIsLoading(false));
    }, [setUser]);

    return (
        <CurrentUser.Provider
            value={{
                userData,
                settings,
                updateUser: setUser,
            }}
        >
            {isLoading || isSettingsLoading ? null : children}
        </CurrentUser.Provider>
    );
};

export const useCurrentUser = (): CurrentUserContextType => useContext(CurrentUser);
