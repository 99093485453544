// @flow stirct
import React, { type Node } from 'react';
import { Grid, Heading } from '@xyz-school/xyz-react-frontend';
import { useTranslation } from 'react-i18next';

import { type ProfileItemsType } from '@/model/Profile';
import MainLayout from '@/features/MainLayout';

import ProfileMenu from '../ProfileMenu';
import ContentWrapper from '../ContentWrapper';
import FormGeneral from '../FormGeneral';
import FormAuthorization from '../FormAuthorization';
import FormPassword from '../FormPassword';
import Photo from '../Photo';

import styles from './DesktopView.scss';

export type Props = {
    currentSection: ProfileItemsType,
    onSetNewMenuItem: (newItem: ProfileItemsType) => void,
};

const getForms = (heading: Node) => ({
    general: (
        <>
            <Grid.Item xl={2}>
                <div className={styles.paddingWrapper}>
                    <Photo />
                </div>
            </Grid.Item>
            <Grid.Item xl={4}>
                {heading}
                <FormGeneral />
            </Grid.Item>
        </>
    ),
    authorization: (
        <>
            <Grid.Item xl={2} />
            <Grid.Item xl={4}>
                {heading}
                <FormAuthorization />
            </Grid.Item>
        </>
    ),
    password: (
        <>
            <Grid.Item xl={2} />
            <Grid.Item xl={4}>
                {heading}
                <FormPassword />
            </Grid.Item>
        </>
    ),
});

const DesktopView = ({ currentSection, onSetNewMenuItem }: Props): Node => {
    const { t } = useTranslation(['profile']);
    const heading = (
        <Heading level={1} className={styles.heading}>
            {t('TITLE')}
        </Heading>
    );
    return (
        <MainLayout>
            <Grid.Row>
                <Grid.Item xl={2}>
                    <div className={styles.paddingWrapper}>
                        <div className={styles.menu}>
                            <ProfileMenu currentItem={currentSection} onChange={onSetNewMenuItem} isShowActive />
                        </div>
                    </div>
                </Grid.Item>
                <ContentWrapper currentContent={currentSection} forms={getForms(heading)} />
            </Grid.Row>
        </MainLayout>
    );
};

export default DesktopView;
