// @flow strict
import React, { type Node, type AbstractComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Container, Text } from '@xyz-school/xyz-react-frontend';

import { withUser, type SocialAuthProviderType } from '@/model/User';
import { ActionLink } from '@/features/TextWithActions';
import SocialAuth from '@/features/SocialAuth';
import useQueryError from '@/features/useQueryError';
import urls from '@/constants/urls';
import ConfirmedEmailActions from './ConfirmedEmailActions';
import UnconfirmedEmailActions from './UnconfirmedEmailActions';

type ViewProps = {|
    user: {
        email: string,
        unconfirmedEmail?: string,
    },
|};

export const FormAuthorizationView = ({ user: { email, unconfirmedEmail } }: ViewProps): Node => {
    const { t } = useTranslation(['profile']);
    useQueryError('socialConnectError', 'PROFILE_SOCIAL_ERROR', `${urls.PROFILE}authorization`);

    const getLink = (provider: SocialAuthProviderType) => `/accounts/${provider}/login/?process=connect`;

    return (
        <>
            <Container marginBottom={4} marginBottomSm={2} paddingBottom={5} paddingBottomSm={3}>
                <Container marginBottom={3} marginBottomSm={2}>
                    <Heading level={3}>E-mail {!!unconfirmedEmail && !email && `(${t('UNCONFIRMED')})`}</Heading>
                </Container>

                {!!email && !unconfirmedEmail && <ConfirmedEmailActions email={email} />}

                {!email && !unconfirmedEmail && (
                    <ActionLink url={urls.SET_EMAIL} size="regular">
                        {t('SET_EMAIL')}
                    </ActionLink>
                )}

                {!!email && !!unconfirmedEmail && (
                    <>
                        <Container marginBottom={3}>
                            <Container marginBottom={0.5}>
                                <Text color="label" size="small">
                                    {t('AUTH_CURRENT')}
                                </Text>
                            </Container>
                            <ConfirmedEmailActions email={email} />
                        </Container>

                        <Container marginBottom={0.5}>
                            <Text color="label" size="small">
                                {t('AUTH_NEW')}
                            </Text>
                        </Container>
                        <UnconfirmedEmailActions email={email} unconfirmedEmail={unconfirmedEmail} />
                    </>
                )}

                {!email && !!unconfirmedEmail && (
                    <UnconfirmedEmailActions email={email} unconfirmedEmail={unconfirmedEmail} />
                )}
            </Container>

            <Container marginBottom={4} marginBottomSm={2} paddingBottom={5} paddingBottomSm={3}>
                <Container marginBottom={3} marginBottomSm={2}>
                    <Heading level={3}>{t('AUTH_SOCIALS')}</Heading>
                </Container>
                <SocialAuth getLink={getLink} />
            </Container>
        </>
    );
};

type Props = {||};

const FormAuthorization: AbstractComponent<Props> = withUser<Props>(FormAuthorizationView);

export default FormAuthorization;
