// @flow strict
import React, { type Node } from 'react';
import { Heading } from '@xyz-school/xyz-react-frontend';
import classnames from 'classnames';

import CenteredLayout from '@/features/CenteredLayout';

import styles from './LayoutHeading.scss';

export type HeadingPropsType = {|
    children: string,
    icon?: Node,
    smallMargin?: boolean,
|};

const Title = ({ isCenter, children }: { isCenter: boolean, children: Node }): Node => (
    <Heading level={1} className={classnames({ [styles.headingCenter]: isCenter })}>
        {children}
    </Heading>
);

const LayoutHeading = ({ children, icon, smallMargin }: HeadingPropsType): Node => {
    return (
        <CenteredLayout className={classnames(styles.layout, { [styles.smallMargin]: smallMargin })}>
            {icon ? (
                <div className={styles.wrapper}>
                    <div className={styles.iconWrapper}>{icon}</div>
                    <Title isCenter>{children}</Title>
                </div>
            ) : (
                <Title isCenter={false}>{children}</Title>
            )}
        </CenteredLayout>
    );
};

export default LayoutHeading;
