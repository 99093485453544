// @flow
import React, { type Node, type AbstractComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Heading, useNotificationActions, NOTIFICATION_TYPE } from '@xyz-school/xyz-react-frontend';

import { type UserType, type SocialLinkType, socials, api, useCurrentUser, withUser } from '@/model/User';

import { useForm } from '@/core/form';

import SubmitControls from '../SubmitControls';

import styles from './FormGeneral.scss';

type FormType = {|
    [social: string]: string,
    username: string,
    birthday: string,
    information: string,
|};

export const getInitialValues = (user: $Shape<UserType> = {}): FormType => {
    const { username = '', birthday, information = '', socialLinks = [] } = user;
    const socialsInForm = Object.fromEntries(socialLinks.map(({ type, link }) => [type, link]));
    return {
        ...socialsInForm,
        username,
        birthday: birthday || '',
        information,
    };
};

type Props = {||};

const FormGeneral: AbstractComponent<Props> = withUser<Props>(({ user }): Node => {
    const { t } = useTranslation(['profile']);
    const { updateUser } = useCurrentUser();
    const { pushNotification } = useNotificationActions();

    const { handleSubmit, values, handleChange, errors, isSubmitting } = useForm<FormType>(
        getInitialValues(user),
        async ({ username, birthday, information, ...socialLinks }, onError) => {
            try {
                const newUser = await api.updateProfile({
                    username,
                    information,
                    birthday: null,
                    socialLinks: socials.map<SocialLinkType>(({ type }) => ({ type, link: socialLinks[type] || '' })),
                });
                updateUser(newUser);
                pushNotification({ type: NOTIFICATION_TYPE.success, message: t('SAVED') });
            } catch (error) {
                onError(error);
            }
        },
        [],
    );
    return (
        <form className={styles.wrapper} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
                <Heading level={3} className={styles.item}>
                    {t('GENERAL_DATA')}
                </Heading>
                <Input
                    name="username"
                    type="text"
                    value={values.username}
                    onChange={handleChange}
                    label={t('NICKNAME')}
                    errorMessage={errors.username}
                    className={styles.item}
                />
                {/* <Input
                    name="birthday"
                    type="text"
                    value={values.birthday}
                    onChange={handleChange}
                    label={t('BIRTHDAY')}
                    errorMessage={errors.email}
                /> */}
            </div>

            <div className={styles.formGroup}>
                <Heading level={3} className={styles.item}>
                    {t('GENERAL_INFO')}
                </Heading>
                <Input
                    type="textarea"
                    name="information"
                    value={values.information}
                    onChange={handleChange}
                    label={t('INFORMATION')}
                    errorMessage={errors.information}
                />
            </div>
            <div className={styles.formGroup}>
                <Heading level={3} className={styles.item}>
                    {t('GENERAL_SOCIALS')}
                </Heading>
                {socials.map(({ type }, index) => (
                    <Input
                        type="text"
                        name={type}
                        value={values[type]}
                        onChange={handleChange}
                        label={type}
                        errorMessage={errors.socialLinks ? errors.socialLinks[type] : ''}
                        key={type}
                        className={index === socials.length - 1 ? '' : styles.item}
                    />
                ))}
            </div>

            <SubmitControls className={styles.submit} isSubmitDisabled={isSubmitting} />
        </form>
    );
});

export default FormGeneral;
