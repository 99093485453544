// @flow strict
import React, { type Node, useEffect, useState } from 'react';

import { api as appApi } from '@/core/api';

import { Content, PageMetaTags, PageError404 } from '@xyz-school/xyz-react-frontend';
import MainLayout from '@/features/MainLayout';

type InfoContentType = {|
    title: string,
    content: string,
|};

type Props = {|
    slug: string,
|};

const PageInfo = ({ slug }: Props): Node => {
    const [{ infoContent, isLoading, isError }, setInfoContent] = useState<{
        infoContent?: InfoContentType,
        isLoading: boolean,
        isError: boolean,
    }>({ isLoading: true, isError: false });

    useEffect(() => {
        appApi('GET', `info/${slug}`)
            .then((res) => {
                setInfoContent({
                    infoContent: {
                        title: res.title,
                        content: res.content,
                    },
                    isLoading: false,
                    isError: false,
                });
            })
            .catch(() => {
                setInfoContent({ isLoading: false, isError: true });
            });
    }, [slug]);

    if (isError) {
        return <PageError404 />;
    }

    if (!infoContent || isLoading) {
        return null;
    }

    return (
        <MainLayout>
            <PageMetaTags title={infoContent.title} />
            <Content title={infoContent.title} content={infoContent.content} />
        </MainLayout>
    );
};

export default PageInfo;
