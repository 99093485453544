// @flow strict

export const STORAGE_SETTINGS_KEY = 'settings';

export const FEATURE = Object.freeze({
    socialNetworkRegistration: 'socialNetworkRegistration',
});

export type FeatureType = $Values<typeof FEATURE>;

export type FeatureItemType = {| name: FeatureType, value: string |};

export type UserSettingsType = {|
    features: FeatureItemType[],
|};

export const EMPTY_USER_SETTINGS: UserSettingsType = {
    features: [],
};
