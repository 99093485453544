// @flow strict
import React, { type Node } from 'react';

import { Icon } from '@xyz-school/xyz-react-frontend';

import styles from './FeedbackModalHeader.scss';

type Props = {|
    onClose: () => void,
|};

const FeedbackModalHeader = ({ onClose }: Props): Node => {
    return (
        <header className={styles.feedbackModalHeader}>
            <button type="button" onClick={onClose} className={styles.feedbackModalHeader__closeButton}>
                <Icon name="close" color="text" />
            </button>
        </header>
    );
};

export default FeedbackModalHeader;
