// @flow
import React, { useState, useEffect, type Node } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLocalizedHistory, useMedia, PageMetaTags } from '@xyz-school/xyz-react-frontend';
import { PROFILE_ITEMS, type ProfileItemsType, useProfileNav } from '@/model/Profile';
import urls from '@/constants/urls';

import DesktopView from './DesktopView';
import MobileView from './MobileView';

const PageProfile = (): Node => {
    const { t } = useTranslation(['profile']);
    const history = useLocalizedHistory();
    const { slug } = useParams();
    const [currentSection, setCurrentSection] = useState<ProfileItemsType>(PROFILE_ITEMS.general);
    const media = useMedia();
    const navItems = useProfileNav();

    const onSetNewMenuItem = (newItem: ProfileItemsType) => {
        history.push(`${urls.PROFILE}${newItem.toLowerCase()}`);
    };

    const activeSection = slug && navItems.includes(PROFILE_ITEMS[slug]) ? PROFILE_ITEMS[slug] : PROFILE_ITEMS.general;

    useEffect(() => {
        if (slug) {
            setCurrentSection(activeSection);
        }
    }, [activeSection, slug]);

    return (
        <>
            <PageMetaTags title={t('TITLE')} />
            {media.lg || media.xl ? (
                <DesktopView currentSection={currentSection} onSetNewMenuItem={onSetNewMenuItem} />
            ) : (
                <MobileView currentSection={currentSection} onSetNewMenuItem={onSetNewMenuItem} />
            )}
        </>
    );
};

export default PageProfile;
