// @flow strict
import { useContext } from 'react';

import { CurrentUser, type FeatureType } from '@/model/User';

import { normalizeFeatureValue } from '../helpers';

type FeaturesType = {
    [FeatureType]: boolean | string,
};

const useFeatures = (): FeaturesType => {
    const { settings } = useContext(CurrentUser);

    return settings.features.reduce((acc, feature) => {
        return {
            ...acc,
            [feature.name]: normalizeFeatureValue(feature.value),
        };
    }, {});
};

export default useFeatures;
