// @flow strict
import { useCallback } from 'react';
import { useNotificationActions, NOTIFICATION_TYPE } from '@xyz-school/xyz-react-frontend';

const useRepeatConfirm = (api: (email: string) => Promise<{}>, message: string): ((email: string) => Promise<void>) => {
    const { pushNotification } = useNotificationActions();

    const send = useCallback(
        async (email: string) => {
            await api(email);
            pushNotification({ type: NOTIFICATION_TYPE.success, message });
        },
        [pushNotification, api, message],
    );

    return send;
};

export default useRepeatConfirm;
