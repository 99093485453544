// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationActions, NOTIFICATION_TYPE } from '@xyz-school/xyz-react-frontend';

import TextWithActions, { ActionButton, ActionLink } from '@/features/TextWithActions';
import { useRepeatConfirm, api, useEmail } from '@/model/User';

import urls from '@/constants/urls';

type Props = {|
    email: string,
    unconfirmedEmail: string,
|};

const UnconfirmedEmailActions = ({ email, unconfirmedEmail }: Props): Node => {
    const { t } = useTranslation(['profile', 'confirmEmail']);
    const sendAgain = useRepeatConfirm(api.changeEmailRepeat, t('confirmEmail:SENT_AGAIN_NOTIFY'));
    const { setNewEmail } = useEmail();
    const { pushNotification } = useNotificationActions();

    const onSendAgain = () => {
        sendAgain(unconfirmedEmail);
    };

    const onCancelChange = async (): Promise<void> => {
        await api.deleteChangeEmail(unconfirmedEmail);
        setNewEmail();
        pushNotification({ type: NOTIFICATION_TYPE.success, message: t('AUTH_CANCEL_SUCCESS') });
    };

    const resendConfirmationLink = (
        <ActionButton onClick={onSendAgain} size="regular">
            {t('AUTH_AGAIN')}
        </ActionButton>
    );
    const cancelChangeEmail = (
        <ActionButton onClick={onCancelChange} size="regular">
            {t('AUTH_CANCEL')}
        </ActionButton>
    );

    const changeEmail = (
        <ActionLink url={urls.CHANGE_EMAIL} size="regular">
            {t('AUTH_CHANGE')}
        </ActionLink>
    );

    const actions =
        !!email && !!unconfirmedEmail
            ? [resendConfirmationLink, cancelChangeEmail]
            : [resendConfirmationLink, changeEmail];

    return <TextWithActions actions={actions} text={unconfirmedEmail} size="regular" />;
};

export default UnconfirmedEmailActions;
